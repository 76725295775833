import React, { useEffect } from "react";
import { useState } from "react";
import { getDocs, collection } from "firebase/firestore";
import { DB } from "../../utils/firebase";
import { useHistory } from "react-router-dom";

import "../hotel-existing-review-card/existing-review.styles.css";
import "./existing-reviews.styles.css";

const ExistingCommentCard = (props) => {
  const history = useHistory();
  const id = props.id;
  const oneAirport = props.oneAirport;
  const displayName = props.displayName;
  const snapShot = props.snapShot;

  let indexing = 0;

  const [isLoading, setisLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [creator, setCreator] = useState("");

  useEffect(async () => {
    const myArray = [];
    let index = 0;
    const querySnapshot = await getDocs(
      collection(DB, id, oneAirport, "comments")
    ).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        index = doc.data().index;
        let comment = doc.data().comment;
        let creator = doc.data().displayName;
        let created = doc.data().createdAt.toDate().toString();
        let splitCreated = created.split(" ");
        let day = splitCreated[0];
        let day1 = splitCreated[1];
        let day2 = splitCreated[2];
        let day3 = splitCreated[3];
        let joinThem = day + " " + " " + day1 + " " + day2 + " " + day3;
        myArray[index] = [index, creator, comment, joinThem];
        setCreator(creator);
      });
      setComments(myArray, ...comments);
    });
    setisLoading(false);
  }, [isLoading]);

  const handleEditing = () => {
    let path = `/states/${id}/${oneAirport}/addone`;
    history.push({
      pathname: path,
      state: {
        id: id,
        oneAirport: oneAirport,
        displayName: displayName,
        snapShot: snapShot,
        indexing: indexing,
      },
    });
  };

  const handleAddReview = () => {
    let path = `/states/${id}/${oneAirport}/addone`;
    history.push({
      pathname: path,
      state: {
        id: id,
        oneAirport: oneAirport,
        displayName: displayName,
        snapShot: snapShot,
        comments: comments.length,
      },
    });
  };
  console.log(displayName, creator)

  return (
    <div>
      <div className="existing-h1">Comment List</div>
      <div className="existing-review-main-div">
        {comments
          ? comments.map((comment, i) => {
              return (
                <div key={i} className="review-indi-main-div">
                  <div className="comment-name">{comment[1]}</div>
                  <div className="review-add">{comment[2]}</div>
                  <div className="review-add">{comment[3]}</div>
                  {displayName === comment[1] ? (
                    <div
                      className="review-edit"
                      onClick={() => {
                        indexing = comment[0];
                        handleEditing();
                      }}
                    >
                      Edit
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
      <div className="review-add-review">
        <div>
          <h1 className="review-another-h1">Would you like to Add another?</h1>
        </div>
        <div>
          <button className="review-add-button" onClick={handleAddReview}>
            Click to Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExistingCommentCard;
