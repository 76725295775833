import React from "react";
import Star from "./stars.component";

function StarExistingRating({ rating, size }) {
  return (
    <span>
      {[1, 2, 3, 4, 5].map((value) => (
        <Star key={value} filled={value <= rating} size={size} />
      ))}
    </span>
  );
}
export default StarExistingRating;
