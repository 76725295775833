import React from "react";
import { connect } from "react-redux";
import { statesList } from "../../store/actions";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "@firebase/auth";

import CardComponent from "../card/card.component";
import YouHaveToSignIN from "../../error-pages/you-have-to-signin.component";
import "../states-list/state-list.styles.css";

class StateList extends React.Component {
  componentDidMount() {
    this.setState({pathName: this.props.match.path})
    
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({ signedIn: true });
      } else {
        this.setState({ signedIn: false });
      }
    });
    let myStates = statesList().payload.default;
    this.setState({ myStates: myStates });

  }

  constructor(props) {
    super(props);
    this.state = {
      signedIn: false,
      myStates: [],
      searchStates: "",
      pathName: ""
    };
    this.handleStates = this.handleStates.bind(this);
    
  }

  handleStates(e, name) {
    e.preventDefault();
    name = e.target.value.toLocaleUpperCase();
    const filteredAirports = this.state.myStates.filter((airports) => {
      return airports.toLocaleUpperCase().includes(name);
    });
    this.setState({ searchStates: filteredAirports });
  }

  
  render() {
    let i = 1;
    return this.state.signedIn ? (
      <div key={i++} className="parent-state-div">
        <div className="input-box-div">
          <div>
            <h1 className="search-state-text">You can Search for State Rather than Scroll</h1>
          </div>
          <div className="type-state-div">
            <input
              className="input-box-states"
              type="text"
              onChange={this.handleStates}
            />
          </div>
        </div>

        {this.state.searchStates
          ? this.state.searchStates.map((state, i) => (
              <Link
                className="link-states"
                to={`${this.state.pathName}/` + `${state}`}
                key={i++}
              >
                <CardComponent key={i++} name={state} controlled={"true"}/>
              </Link>
            ))
          : this.state.myStates.map((state, i) => (
              <Link
                className="link-states"
                to={`${this.state.pathName}/`+ `${state}`}
                key={i++}
              >
                <CardComponent key={i++} name={state} controlled={"true"}/>
              </Link>
            ))}
      </div>
    ) : (
      <YouHaveToSignIN />
    );
  }
}

const mapStateToProps = (state) => ({
  states: state,
});

const mapDispatchToProps = {
  statesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(StateList);
