import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { DB } from "../utils/firebase";

import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";
import "./api.styles.css";

//EMAIL STUFF
const API =
  "SG.uS2QF8kMT2azS8TBhIAcAA.GkMbTkkGxLktMD6CzTYQY5i5sf9uZWfC1hSa2Hkrke4";
const sgMail = require("@sendgrid/mail");
const Template_id = "166623b3-357c-49f4-9584-eed1dbe737c9";
sgMail.setApiKey(API);

toast.configure();

//REGISTER THE USER
export const registerUser = ({
  firstName,
  lastName,
  email,
  password,
  displayName,
}) => {
  const auth = getAuth();
  createUserWithEmailAndPassword(auth, email, password)
    .then((cred) => {
      const user = cred.user;

      setDoc(doc(DB, "users", user.uid), {
        uid: user.uid,
        email: email,
        firstname: firstName,
        lastname: lastName,
        displayName: displayName,
        password: "#####",
        timestamp: moment().format("MMMM Do YYYY, h:mm"),
        admin: false,
      });
      toast.success("YOU ARE SIGNED UP", {
        position: "bottom-center",
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        className: "toast-signup-containter",
      });
    })
    .catch((error) => {
      toast.error("OOPS SOMETING WENT WRONG", {
        position: "bottom-center",
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        className: "toast-signup-error-containter",
      });
    });
};

//SIGN IN THE USER WITH TOASTS
export const signInWithEmailandPassword = ({ email, password }) => {
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      if (user) {
        toast.success("YOU ARE SIGNED IN", {
          position: "bottom-center",
          autoClose: 3000,
          closeOnClick: true,
          draggable: true,
          className: "toast-signin-containter",
        });
      }
    })
    .catch((error) => {
      toast.error("OOPS SOMETING WENT WRONG", {
        position: "bottom-center",
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        className: "toast-signin-error-containter",
      });
    });
};

//SIGN OUT THE USER WITH TOASTS
export const SignOutUser = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      toast.success("YOU ARE SIGNED OUT", {
        position: "bottom-center",
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        className: "toast-signout-containter",
      });
    })
    .catch((error) => {
      toast.error("OOPS SOMETING WENT WRONG", {
        position: "bottom-center",
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        className: "toast-signout-error-containter",
      });
    });
};
