import React from "react";

import "./you-have-to-signin.styles.css";

const YouHaveToSignIN = () => (
  <div className="total-youhavetosignin">
     <div className="parent-div">
    <div className="child-div">
      <h1 className="child-h1">YOU HAVE TO SIGN IN FIRST</h1>
    </div>
  </div>
  </div>
 
);

export default YouHaveToSignIN;
