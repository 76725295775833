import React from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import StarRating from "../stars/starrating.component";

import "./hotel-rv-form.styles.css";
import { useState } from "react";
import { DB } from "../../utils/firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";

const ReviewSchema = Yup.object().shape({
  hotelName: Yup.string().min(6, "5 Characters?").required("Required"),
  hotelAdd: Yup.string().required("Required"),
  hotelPhone: Yup.string()
    .min(10, "With Area Code this Should be 10 digits?")
    .required("Required"),
  hotelQuality: Yup.string().required("Required"),
  hotelBreakfast: Yup.string().required("Required"),
  hotelGym: Yup.string().required("Required"),
  hotelRest: Yup.string().required("Required"),
  hotelShuttle: Yup.string().required("Required"),
  hotelLaundry: Yup.string().required("Required"),
  hotelNoise: Yup.string().required("Required"),
  hotelSaftey: Yup.string().required("Required"),
  hotelOther: Yup.string().required("Required"),
});

const HotelReviewComponent = (props) => {
  const history = useHistory();
  const myId = props.myId;
  const oneAirport = props.oneAirport;
  const displayName = props.displayName;
  const snapShot = props.snapShot;
  const indexing = history.location.state.indexing;
  const reviewLength = history.location.state.reviews;

  const [rating, setRating] = useState(0);
  let [isLoading, setLoading] = useState(true);
  const [hotelName, sethtName] = useState("");
  const [hotelAdd, sethtAdd] = useState("");
  const [hotelPhone, sethtPhone] = useState("");
  const [hotelBreakfast, sethtBreak] = useState("");
  const [hotelGym, sethtGym] = useState("");
  const [hotelLaundry, sethtLaundry] = useState("");
  const [hotelNoise, sethtNoise] = useState("");
  const [hotelOther, sethtOther] = useState("");
  const [hotelQuality, sethtQual] = useState("");
  const [hotelRest, sethtRest] = useState("");
  const [hotelSaftey, sethtSaftey] = useState("");
  const [hotelShuttle, sethtShuttle] = useState("");

  function log(value) {
    setRating(value);
  }
  useEffect(async () => {
    if (snapShot !== null && indexing) {
      const docRef = doc(DB, myId, oneAirport, "hotels", indexing.toString());
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let hotelName = docSnap.data().hotelName;
        let hotelAdd = docSnap.data().hotelAdd;
        let hotelPhone = docSnap.data().hotelPhone;
        let hotelBreakfast = docSnap.data().hotelBreakfast;
        let hotelGym = docSnap.data().hotelGym;
        let hotelLaundry = docSnap.data().hotelLaundry;
        let hotelNoise = docSnap.data().hotelNoise;
        let hotelOther = docSnap.data().hotelOther;
        let hotelQuality = docSnap.data().hotelQuality;
        let hotelRest = docSnap.data().hotelRest;
        let hotelSaftey = docSnap.data().hotelSaftey;
        let hotelShuttle = docSnap.data().hotelShuttle;
        let rating = docSnap.data().rating;

        setRating(rating);
        sethtName(hotelName);
        sethtAdd(hotelAdd);
        sethtPhone(hotelPhone);
        sethtBreak(hotelBreakfast);
        sethtGym(hotelGym);
        sethtLaundry(hotelLaundry);
        sethtNoise(hotelNoise);
        sethtOther(hotelOther);
        sethtQual(hotelQuality);
        sethtRest(hotelRest);
        sethtSaftey(hotelSaftey);
        sethtShuttle(hotelShuttle);
      }
    }

    setLoading(false);
  }, [isLoading]);

  return (
  <div className="hotel-total-div">
    <Formik
      enableReinitialize
      initialValues={{
        hotelName: hotelName ? hotelName : "",
        hotelAdd: hotelAdd ? hotelAdd : "",
        hotelPhone: hotelPhone ? hotelPhone : "",
        hotelQuality: hotelQuality ? hotelQuality : "",
        hotelBreakfast: hotelBreakfast ? hotelBreakfast : "",
        hotelGym: hotelGym ? hotelGym : "",
        hotelRest: hotelRest ? hotelRest : "",
        hotelShuttle: hotelShuttle ? hotelShuttle : "",
        hotelLaundry: hotelLaundry ? hotelLaundry : "",
        hotelNoise: hotelNoise ? hotelNoise : "",
        hotelSaftey: hotelSaftey ? hotelSaftey : "",
        hotelOther: hotelOther ? hotelOther : "",
      }}
      validationSchema={ReviewSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        ////////////FUNCTION add First /////////////
        const onAddFirstReview = async () => {
          if (snapShot === null) {
            /////////////////////////////////////
            ///Add First Review/////////////////
            await setDoc(doc(DB, myId, oneAirport, "hotels", "1"), {
              index: 1,
              displayName: displayName,
              createdAt: new Date(),
              rating: rating,
              hotelName: values.hotelName,
              hotelAdd: values.hotelAdd,
              hotelPhone: values.hotelPhone,
              hotelQuality: values.hotelQuality,
              hotelBreakfast: values.hotelBreakfast,
              hotelGym: values.hotelGym,
              hotelRest: values.hotelRest,
              hotelShuttle: values.hotelShuttle,
              hotelLaundry: values.hotelLaundry,
              hotelNoise: values.hotelNoise,
              hotelSaftey: values.hotelSaftey,
              hotelOther: values.hotelOther,
            });
          }
          ////////////////////////////////
          ///// Edit Existing ///////////
          if (snapShot && indexing) {
            await setDoc(
              doc(DB, myId, oneAirport, "hotels", indexing.toString()),
              {
                index: indexing,
                displayName: displayName,
                createdAt: new Date(),
                rating: rating,
                hotelName: values.hotelName,
                hotelAdd: values.hotelAdd,
                hotelPhone: values.hotelPhone,
                hotelQuality: values.hotelQuality,
                hotelBreakfast: values.hotelBreakfast,
                hotelGym: values.hotelGym,
                hotelRest: values.hotelRest,
                hotelShuttle: values.hotelShuttle,
                hotelLaundry: values.hotelLaundry,
                hotelNoise: values.hotelNoise,
                hotelSaftey: values.hotelSaftey,
                hotelOther: values.hotelOther,
              }
            );
          }
          ///////////////////////////////////
          ///////// Add new Reivew ///////////
          if (snapShot && reviewLength) {
            await setDoc(
              doc(DB, myId, oneAirport, "hotels", reviewLength.toString()),
              {
                index: reviewLength,
                displayName: displayName,
                createdAt: new Date(),
                rating: rating,
                hotelName: values.hotelName,
                hotelAdd: values.hotelAdd,
                hotelPhone: values.hotelPhone,
                hotelQuality: values.hotelQuality,
                hotelBreakfast: values.hotelBreakfast,
                hotelGym: values.hotelGym,
                hotelRest: values.hotelRest,
                hotelShuttle: values.hotelShuttle,
                hotelLaundry: values.hotelLaundry,
                hotelNoise: values.hotelNoise,
                hotelSaftey: values.hotelSaftey,
                hotelOther: values.hotelOther,
              }
            );
          }
        };
        /////////////////////////////////////////////////
        let path = `/hotels/states/${myId}/${oneAirport}`;
        history.replace({
          pathname: path,
          id: myId,
          oneAirport: oneAirport,
          displayName: displayName,
        });
        onAddFirstReview();
      }}
    >
      {({ errors, touched }) => (
        
        <Form className="form-hotelrv">
          <div className="inner-div-hotelrv">
            <div>
              <div>
                <h1 className="hotel-h1-text">You are adding a Review for:</h1>
                <h2 className="hotel-h1-text" id="h1-review-airport">
                  {oneAirport} Review {indexing}
                </h2>
              </div>
              <div>
                <h2 className="hotel-h2-text">
                  Enter N/A where not applicable
                </h2>
              </div>
            </div>

            <div className="hotel-name">
              <div htmlFor="hotel-name" className="hotel-text">
                Hotel Name:
              </div>
              <Field
                name="hotelName"
                as="textarea"
                className="input-box-nameaddphone"
              />
              {errors.hotelName && touched.hotelName ? (
                <div className="errors-txt">{errors.hotelName}</div>
              ) : null}
            </div>

            <div className="hotel-address">
              <div htmlFor="hotel-address" className="address-text">
                Hotel Address:
              </div>
              <Field
                name="hotelAdd"
                as="textarea"
                className="input-box-nameaddphone"
              />
              {errors.hotelAdd && touched.hotelAdd ? (
                <div className="errors-txt">{errors.hotelAdd}</div>
              ) : null}
            </div>

            <div className="hotel-phone">
              <div htmlFor="hotel-phone" className="phone-text">
                Hotel Phone:
              </div>
              <Field
                name="hotelPhone"
                as="textarea"
                className="input-box-nameaddphone"
              />
              {errors.hotelPhone && touched.hotelPhone ? (
                <div className="errors-txt">{errors.hotelPhone}</div>
              ) : null}
            </div>

            <div className="hotel-quality">
              <div htmlFor="hotel-quality" className="quality-text">
                Hotel Quality:
              </div>
              <Field
                name="hotelQuality"
                as="textarea"
                className="input-box-hotelrv"
              />
              {errors.hotelQuality && touched.hotelQuality ? (
                <div className="errors-txt">{errors.hotelQuality}</div>
              ) : null}
            </div>

            <div className="hotel-breakfast">
              <div htmlFor="hotel-breakfast" className="breakfast-text">
                Hotel Breakfast:
              </div>
              <Field
                name="hotelBreakfast"
                as="textarea"
                className="input-box-hotelrv"
              />
              {errors.hotelBreakfast && touched.hotelBreakfast ? (
                <div className="errors-txt">{errors.hotelBreakfast}</div>
              ) : null}
            </div>

            <div className="hotel-gym">
              <div htmlFor="hotel-gym" className="gym-text">
                Hotel Gym:
              </div>
              <Field
                name="hotelGym"
                as="textarea"
                className="input-box-hotelrv"
              />
              {errors.hotelGym && touched.hotelGym ? (
                <div className="errors-txt">{errors.hotelGym}</div>
              ) : null}
            </div>

            <div className="hotel-rest">
              <div htmlFor="hotel-rest" className="rest-text">
                Hotel Restaurant:
              </div>
              <Field
                name="hotelRest"
                as="textarea"
                className="input-box-hotelrv"
              />
              {errors.hotelRest && touched.hotelRest ? (
                <div className="errors-txt">{errors.hotelRest}</div>
              ) : null}
            </div>

            <div className="hotel-shuttle">
              <div htmlFor="hotel-shuttle" className="shuttle-text">
                Hotel Shuttle Service:
              </div>
              <Field
                name="hotelShuttle"
                as="textarea"
                className="input-box-hotelrv"
              />
              {errors.hotelShuttle && touched.hotelShuttle ? (
                <div className="errors-txt">{errors.hotelShuttle}</div>
              ) : null}
            </div>

            <div className="hotel-laundry">
              <div htmlFor="hotel-laundry" className="laundry-text">
                Hotel Laundry Service:
              </div>
              <Field
                name="hotelLaundry"
                as="textarea"
                className="input-box-hotelrv"
              />
              {errors.hotelLaundry && touched.hotelLaundry ? (
                <div className="errors-txt">{errors.hotelLaundry}</div>
              ) : null}
            </div>

            <div className="hotel-noise">
              <div htmlFor="hotel-noise" className="noise-text">
                Hotel Noise Considerations:
              </div>
              <Field
                name="hotelNoise"
                as="textarea"
                className="input-box-hotelrv"
              />
              {errors.hotelNoise && touched.hotelNoise ? (
                <div className="errors-txt">{errors.hotelNoise}</div>
              ) : null}
            </div>

            <div className="hotel-saftey">
              <div htmlFor="hotel-saftey" className="saftey-text">
                Hotel Saftey Concerns:
              </div>
              <Field
                name="hotelSaftey"
                as="textarea"
                className="input-box-hotelrv"
              />
              {errors.hotelSaftey && touched.hotelSaftey ? (
                <div className="errors-txt">{errors.hotelSaftey}</div>
              ) : null}
            </div>

            <div className="hotel-other">
              <div htmlFor="hotel-other" className="other-text">
                Hotel Other Notes:
              </div>
              <Field
                name="hotelOther"
                as="textarea"
                className="input-box-hotelrv"
              />
              {errors.hotelOther && touched.hotelOther ? (
                <div className="errors-txt">{errors.hotelOther}</div>
              ) : null}
            </div>
            <div className="other-text">Enter Star Rating:</div>
            <StarRating onChange={log} size={50} />
              <div>
                <button type="submit" className="review-button">
                  SUBMIT
                </button>
              </div>
          </div>
        </Form>
      )}
    </Formik>
    </div>
  );
};

export default HotelReviewComponent;
