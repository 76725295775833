import { STATE_LIST, AUTH_USER } from "../types/types";


import * as stateList from "../../data/states.json";

import * as api from "../../api";

//////////////////////////////////////////////////////////////////////////

//REGISTER USER
export const registerUser = (userData) => ({
  type: AUTH_USER,
  payload: api.registerUser(userData),
});

//SIGN IN USER
export const signInWithEmailandPassword = (userData) => ({
  type: AUTH_USER,
  payload: api.signInWithEmailandPassword(userData),
});

//SIGN OUT THE USER
export const SignOutUser = (userData) => ({
  type: AUTH_USER,
  payload: api.SignOutUser(userData),
});


////////////////////////////////////////////////////////////////////////////////

//Hard Coded Lists
export function statesList() {
  return {
    type: STATE_LIST,
    payload: stateList,
  };
}