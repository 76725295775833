import React from "react";
import { doc, getDoc } from "firebase/firestore";
import { DB } from "../../utils/firebase";
import { getAuth } from "firebase/auth";

import "../airport-landingpage/airport-landingpage.styles.css";

import { Button } from "react-bootstrap";

class AirportLandingPage extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
    //We have Mounted
    this.mounted = true;

    //Set ID and oneAirport
    const id = this.props.match.params.id;
    const oneAirport = this.props.match.params.airport;
    this.setState({ id: id, oneAirport: oneAirport });

    ///Get All the Information for Overview ++ Snapshot == true
    const docRef = doc(DB, id, oneAirport);
    if (this.mounted) {
      const docSnap = await getDoc(docRef);
      this.setState({
        address: docSnap.data().Address,
        city: docSnap.data().City,
        controlled: docSnap.data().Controlled.toString().toUpperCase(),
        icao: docSnap.data().Icao,
        lat: docSnap.data().Lat,
        lng: docSnap.data().Long,
        formalName: docSnap.data().Name,
        phone: docSnap.data().Phone,
        timeZone: docSnap.data().Timezone,
        zipCode: docSnap.data().Zipcode,
      });

      //Is User Admin
      const auth = getAuth();
      const user = auth.currentUser;
      const docRef1 = doc(DB, "users", user.uid);
      const docSnap1 = await getDoc(docRef1);
      const adminBool = Boolean(docSnap1.data().admin);
      this.setState({ admin: adminBool });
      this.setState({ displayName: docSnap1.data().displayName });

      //Google Maps Location
      const initialValue = this.state.lat;
      const array = initialValue.split(" ");

      const latitudeArray = array[0];
      this.setState({ lat: latitudeArray });
      const longitudeArray = array[1];
      this.setState({ lng: longitudeArray });

      // Latitude First
      const latTotal = latitudeArray.split("-");

      const latDeg = parseInt(latTotal[0]);
      const latSec = parseInt(latTotal[1]);
      const latMin = latTotal[2];

      //Split the Mintues into Minutes and Cardinal Direction
      const minSplit = latMin.split(".");
      const newMin = parseInt(minSplit[0]);
      const positionCardinal = minSplit[1];
      const boolCardinal =
        positionCardinal.includes("S") || positionCardinal.includes("W");

      //Longitude Now

      const longTotal = longitudeArray.split("-");

      const longDeg = parseInt(longTotal[0]);
      const longSec = parseInt(longTotal[1]);
      const longMin = longTotal[2];

      //Longitutde Mintues
      const longMinSplit = longMin.split(".");
      const newLongMin = parseInt(longMinSplit[0]);
      const posLngCard = longMinSplit[1];
      const boolLngCard = posLngCard.includes("S") || posLngCard.includes("W");

      const converterLatandLong = (deg, sec, min, cardinal) => {
        let final = 0;
        if (cardinal) {
          final = (deg + sec / 60 + min / 3600) * -1;
        } else {
          final = deg + sec / 60 + min / 3600;
        }
        return final.toFixed(8);
      };
      this.setState({
        decLat: converterLatandLong(latDeg, latSec, newMin, boolCardinal),
      });

      this.setState({
        decLng: converterLatandLong(longDeg, longSec, newLongMin, boolLngCard),
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      oneAirport: "",
      address: "",
      city: "",
      controlled: "",
      icao: "",
      lat: "",
      lng: "",
      formalName: "",
      phone: "",
      timeZone: "",
      zipCode: "",
      decLat: 11,
      decLng: 11,
      uid: "",
      admin: "",
      snapShot: null,
      displayName: "",
    };
    this.routeChangeOverView = this.routeChangeOverView.bind(this);
    this.routeChangePilotAdmin = this.routeChangePilotAdmin.bind(this);
    this.routeChangePilotNotAdmin = this.routeChangePilotNotAdmin.bind(this);
    this.routeChangeGoogleMaps = this.routeChangeGoogleMaps.bind(this);
    this.routeChangeComments = this.routeChangeComments.bind(this);
  }

  routeChangeOverView() {
    let path = `/states/${this.state.id}/${this.state.oneAirport}/overview`;
    this.props.history.push({
      pathname: path,
      state: { data: this.state },
    });
  }

  routeChangePilotAdmin() {
    let path = `/states/${this.state.id}/${this.state.oneAirport}/pilotinfo/admin`;
    this.props.history.push({
      pathname: path,
      state: { data: this.state },
    });
  }

  routeChangePilotNotAdmin() {
    let path = `/states/${this.state.id}/${this.state.oneAirport}/pilotinfo`;
    this.props.history.push({
      pathname: path,
      state: { data: this.state },
    });
  }

  routeChangeGoogleMaps() {
    let path = `/states/${this.state.id}/${this.state.oneAirport}/googlemaps`;
    this.props.history.push({
      pathname: path,
      state: { data: this.state },
    });
  }

  routeChangeComments() {
    let path = `/states/${this.state.id}/${this.state.oneAirport}/comments`;
    this.props.history.push({
      pathname: path,
      state: { data: this.state },
    });
  }

  render() {
    return (
      <div className="total-landingpage">
        <div className="landing-page-main-div">
          <Button
            className="overview-button"
            onClick={this.routeChangeOverView}
          >
            Overview Page
          </Button>
          <Button
            className="pilot-info-button"
            onClick={
              this.state.admin === true
                ? this.routeChangePilotAdmin
                : this.routeChangePilotNotAdmin
            }
          >
            Pilot Information
          </Button>
          <Button
            className="google-map-button"
            onClick={this.routeChangeGoogleMaps}
          >
            To Google Maps
          </Button>
          <Button
            className="comments-button"
            onClick={this.routeChangeComments}
          >
            Comments
          </Button>
        </div>
      </div>
    );
  }
}
export default AirportLandingPage;
