import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { DB } from "../../utils/firebase";

import { SignOutUser } from "../../store/actions";

import "../header/header.styles.css";

const Header = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [displayName, setDisplayNm] = useState(null);
  const [firstName, setFirst] = useState(null);
  const [lastName, setLast] = useState(null);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(true);
      } else {
        setCurrentUser(false);
      }
    });
    const getUser = async (user) => {
      if (user) {
        const docRef = doc(DB, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          if (docSnap.data().displayName) {
            setDisplayNm(docSnap.data().displayName);
            setFirst(docSnap.data().firstname);
            setLast(docSnap.data().lastname);
            setUid(docSnap.data().uid);
          }
        } else {
          // doc.data() will be undefined in this case
        }
      }
    };
    getUser(user);
  });

  const setDisplayName = () => {
    setDisplayNm(null);
    SignOutUser();
  };

  return currentUser ? (
    <div className="header-container">
      <Link to="/" className="header-home">
        HOME
      </Link>
      <Link to="/" className="header-sign-out" onClick={setDisplayName}>
        SIGN OUT
      </Link>
      <Link
        to={{
          pathname: "/profile",
          state: {
            displayName: displayName,
            firstName: firstName,
            lastName: lastName,
            uid: uid,
          },
        }}
        className="header-sign-out"
      >
        {displayName}
      </Link>
    </div>
  ) : (
    <div className="header-container">
      <Link to="/" className="header-home">
        HOME
      </Link>
      <Link to="/signin" className="header-sign-in">
        SIGN IN
      </Link>
      <Link to="/signup" className="header-sign-up">
        SIGN UP
      </Link>
    </div>
  );
};

export default Header;
