import React from "react";
import { Route, Switch } from "react-router-dom";

import "./App.css";

import Header from "./components/header/header.component";
import HotelRest from "./components/hotel-restaurants/hotel-restaurants.component";
import SignIn from "./components/sign-in/sign-in.component";
import SignUp from "./components/sign-up/sign-up.component";
import StateCodes from "./components/states-code/states-code.component";
import StateList from "./components/states-list/states-list.component";
import AirportLandingPage from "./components/airport-landingpage/airport-landingpage.component";
import AiportOverviewPage from "./components/aiport-overview/airport-overview.component";
import PilotComponent from "./components/pilot-info-admin/pilot-admin.component";
import PilotNotAdminComponent from "./components/pilot-not-admin/pilot-not-admin.component";
import GMViewer from "./components/google-maps/google.map.component";
import ForgotPass from "./components/forgot-pass/forgot-pass.component";
import ComingSoon from "./error-pages/comingsoon.component";
import HotelReviewLandingPage from "./components/hotelreview-landingpage/hotelreview-landingpage.component";
import RestaurantsReviewLandingPage from "./components/restreview-landingpage/restreview-landingpage.component";
import HTnoReview from "./components/hotel-noreview/ht-noreview.component";
import HotelViewOnly from "./components/hotel-review-view-info/ht-viewinfo.component";
import CommentLandingPage from "./components/airport-comment-landingpage/airport-comment-landingpage.component";
import NoComment from "./components/airport-comment-nocomment/nocomment.component";
import ProfileComponent from "./components/profile-page/profile.component";
import RestnoReview from "./components/rest-noreview/rest-noreview.component";
import RestViewONly from "./components/rest-review-viewonly/rest-review-viewonly.component";

const App = () => {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route
          path="/states/:id/:airport/pilotinfo/admin"
          component={PilotComponent}
        />
        <Route
          path="/restaurants/states/:id/:airport/restreviews/viewonly"
          component={RestViewONly}
        />

        <Route
          path="/hotels/states/:id/:airport/hotelreviews/viewonly"
          component={HotelViewOnly}
        />
        <Route
          path="/restaurants/states/:id/:airport/restreviews/addone"
          component={RestnoReview}
        />
        <Route
          path="/hotels/states/:id/:airport/hotelreviews/addone"
          component={HTnoReview}
        />
        <Route path="/states/:id/:airport/addone" component={NoComment} />
        <Route
          path="/states/:id/:airport/comments"
          component={CommentLandingPage}
        />
        <Route path="/states/:id/:airport/googlemaps" component={GMViewer} />
        <Route
          path="/states/:id/:airport/pilotinfo"
          component={PilotNotAdminComponent}
        />
        <Route
          path="/states/:id/:airport/overview"
          component={AiportOverviewPage}
        />
        <Route
          exact
          path="/states/:id/:airport"
          component={AirportLandingPage}
        />
        <Route
          exact
          path="/hotels/states/:id/:airport"
          component={HotelReviewLandingPage}
        />
        <Route
          exact
          path="/restaurants/states/:id/:airport"
          component={RestaurantsReviewLandingPage}
        />
        <Route exact path="/states/:id" component={StateCodes} />
        <Route exact path="/restaurants/states/:id" component={StateCodes} />
        <Route exact path="/hotels/states/:id" component={StateCodes} />
        <Route exact path="/states" component={StateList} />
        <Route exact path="/restaurants/states" component={StateList} />
        <Route exact path="/hotels/states" component={StateList} />

        <Route path="/signup" component={SignUp} />
        <Route path="/comingsoon" component={ComingSoon} />
        <Route path="/forgot" component={ForgotPass} />
        <Route path="/profile" component={ProfileComponent} />
        <Route path="/signin" component={SignIn} />
        <Route exact path="/" component={HotelRest} />
      </Switch>
    </div>
  );
};

export default App;
