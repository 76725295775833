import React from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import "./profile.styles.css";
import PreviewImage from "../../functions/previewimage";

import { useState } from "react";
import { DB, storage } from "../../utils/firebase";
import { ref, uploadBytes } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { toast } from "react-toastify";

const ReviewSchema = Yup.object().shape({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  displayname: Yup.string().required("Required"),
  userImage: Yup.mixed()
    .required("Required")
    .test(
      "FILE_SIZE",
      "Too Big!",
      (values) => values && values.size < 1024 * 1024
    )
    .test(
      "FILE_TYPE",
      "Invalid",
      (values) =>
        values && ["image/png", "image/jpeg", "image/jpg"].includes(values.type)
    ),
});

const ProfileComponent = (props) => {
  const history = useHistory();

  let [isLoading, setLoading] = useState(true);
  const [firstName, setFirst] = useState("");
  const [lastName, setLast] = useState("");
  const [displayName, setDisName] = useState("");
  const [userImage, setImage] = useState(null);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    if (history.location.state) {
      setFirst(history.location.state.firstName);
      setLast(history.location.state.lastName);
      setDisName(history.location.state.displayName);
      setImage(history.location.state.userImage);
      setUid(history.location.state.uid);
    }

    setLoading(false);
  }, [isLoading]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstname: firstName ? firstName : "",
        lastname: lastName ? lastName : "",
        displayname: displayName ? displayName : "",
        userImage: userImage ? userImage : "",
      }}
      validationSchema={ReviewSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        async function upDateUser() {
          const userRef = doc(DB, "users", uid);

          await updateDoc(userRef, {
            firstname: values.firstname,
            lastname: values.lastname,
            displayName: values.displayname,
            profilePic: values.userImage.name,
          });
        }

        async function UploadImage() {
          const fileRef = ref(storage, values.userImage.name);
          const snapShot = await uploadBytes(fileRef, values.userImage);
          toast.success("UPDATED", {
            position: "bottom-center",
            autoClose: 3000,
            closeOnClick: true,
            draggable: true,
            className: "toast-signup-containter",
          });
        }
        UploadImage();
        upDateUser();

        history.push("/");
      }}
    >
      {({ errors, touched, values, setFieldValue, handleSubmit }) => (
        <Form className="profile-main-form" onSubmit={handleSubmit}>
          <div className="profile-inner-div-left">
            <div>
              <div>
                <h1 className="profile-h1-text">Editing Profile for:</h1>
                <h2 className="profile-text-displayname">{displayName}</h2>
                <h2 className="profile-h2-text">
                  Changing Display Name makes you
                </h2>
                <h2 className="profile-h2-text">
                  Unable to edit previous Comments/Reviews
                </h2>
              </div>
            </div>

            <div className="profile-firstname">
              <div htmlFor="profile-firstname" className="profile-text">
                First Name:
              </div>
              <Field
                name="firstname"
                as="input"
                className="input-box-profile"
                onChange={(event) =>
                  setFieldValue("firstname", event.target.value)
                }
              />
              {errors.firstname && touched.firstname ? (
                <div className="errors-txt">{errors.firstname}</div>
              ) : null}
            </div>

            <div className="profile-lastname">
              <div htmlFor="profile-lastname" className="profile-text">
                Last Name:
              </div>
              <Field
                name="lastname"
                as="input"
                className="input-box-profile"
                onChange={(event) =>
                  setFieldValue("lastname", event.target.value)
                }
              />
              {errors.lastname && touched.lastname ? (
                <div className="errors-txt">{errors.lastname}</div>
              ) : null}
            </div>

            <div className="profile-displayname">
              <div htmlFor="profile-displayname" className="profile-text">
                Display Name:
              </div>
              <Field
                name="displayname"
                as="input"
                className="input-box-profile"
                onChange={(event) =>
                  setFieldValue("displayname", event.target.value)
                }
              />
              {errors.displayname && touched.displayname ? (
                <div className="errors-txt">{errors.displayname}</div>
              ) : null}
            </div>
          </div>

          <div className="profile-inner-div-right">
            <div>
              <h1 className="profile-h1-text">Profile Image:</h1>
              <br />
              {values.userImage && <PreviewImage file={values.userImage} />}
              <input
                name="userImage"
                type="file"
                className="profile-choose-file"
                onChange={(event) => {
                  setFieldValue("userImage", event.target.files[0]);
                }}
              />
              {errors.userImage ? (
                <div className="errors-txt">{errors.userImage}</div>
              ) : null}

              <div>
                <button type="submit" className="review-button">
                  Update
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileComponent;
