import { STATE_LIST } from "../types/types";

export default function (state = null, action) {
  switch (action.type) {
    case STATE_LIST:
      return action.payload;
    default:
      return state;
  }
}
