import React, { useEffect } from "react";
import { useState } from "react";
import { getDocs, collection } from "firebase/firestore";
import { DB } from "../../utils/firebase";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import "./existing-review.styles.css";
import StarExistingRating from "../stars/star-existing.component";

const ExistingReviewCard = (props) => {
  const history = useHistory();
  const id = props.id;
  const oneAirport = props.oneAirport;
  const displayName = props.displayName;
  const snapShot = props.snapShot;

  let indexing = "0";

  const [isLoading, setisLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [creator, setCreator] = useState("");

  useEffect(async () => {
    const myArray = [];
    let index = 0;
    const querySnapshot = await getDocs(
      collection(DB, id, oneAirport, "hotels")
    ).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        index = doc.data().index;
        let hotelName = doc.data().hotelName;
        let hotelAdd = doc.data().hotelAdd;
        let hotelPhone = doc.data().hotelPhone;
        let rating = doc.data().rating;
        let creator = doc.data().displayName;
        myArray[index] = [index, hotelName, hotelAdd, hotelPhone, rating];
        setCreator(creator);
      });
      setReviews(myArray, ...reviews);
    });
    setisLoading(false);
  }, [isLoading]);

  const handleEditing = () => {
    let path = `/hotels/states/${id}/${oneAirport}/hotelreviews/addone`;
    history.push({
      pathname: path,
      state: {
        myId: id,
        oneAirport: oneAirport,
        displayName: displayName,
        snapShot: snapShot,
        indexing: indexing,
      },
    });
  };

  const handleAddReview = () => {
    let path = `/hotels/states/${id}/${oneAirport}/hotelreviews/addone`;
    history.push({
      pathname: path,
      state: {
        myId: id,
        oneAirport: oneAirport,
        displayName: displayName,
        snapShot: snapShot,
        reviews: reviews.length,
      },
    });
  };

  return (
    <div className="existing-total">
      <div className="existing-h1">Hotel Reviews List</div>
      <div className="existing-review-main-div">
        {reviews
          ? reviews.map((review, i) => {
              return (
                <div key={i} className="review-indi-main-div">
                  <Link className="link-reviews"
                    to={{
                      pathname: `/hotels/states/${id}/${oneAirport}/hotelreviews/viewonly`,
                      state: {
                        myId: id,
                        oneAirport: oneAirport,
                        displayName: displayName,
                        snapShot: snapShot,
                        indexing: review[0],
                      },
                    }}
                  >
                    <div className="review-name">{review[1]}</div>
                  </Link>
                  <div className="review-add">{review[2]}</div>
                  <div className="review-phone">{review[3]}</div>
                  <StarExistingRating rating={review[4]} size={24} />
                  {displayName == creator ? (
                    <div
                      className="review-edit"
                      onClick={() => {
                        indexing = review[0];
                        handleEditing();
                      }}
                    >
                      Edit
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
      <div className="review-add-review">
        <div>
          <h1 className="review-another-h1">Would you like to Add another?</h1>
        </div>
        <div>
          <button className="review-add-button" onClick={handleAddReview}>
            Click to Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExistingReviewCard;
