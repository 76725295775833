import React from "react";

import "./you-have-to-signin.styles.css";

const ComingSoon = () => (
  <div className="form-main-sign-in">
     <div className="parent-div">
    <div className="child-div">
      <h1 className="child-h1">COMING SOON</h1>
    </div>
  </div>
  </div>
 
);

export default ComingSoon;
