import React from "react";

import "../aiport-overview/airport-overview.styles.css";

class AiportOverviewPage extends React.Component {
  render() {
    const myData = this.props.location.state.data;

    return (
      <div className="airport-total-div">
      <div className="airport-overview-main-div">
        <div className="name-div">
          <div className="indiv-div-icao">{myData.icao}</div>
          <div className="indiv-div-over">{myData.city}</div>
          <div className="indiv-div-over">{myData.formalName}</div>
          <div className="indiv-div-over">Time Zone: {myData.timeZone}</div>
        </div>

        <div className="lat-long-div">
          <div className="indiv-div-over">Lat: {myData.lat}</div>
          <div className="indiv-div-over">Long: {myData.lng}</div>
        </div>

        <div className="address-div">
          <div className="indiv-div-over">Address: {myData.address}</div>
          <div className="indiv-div-over">Zip Code: {myData.zipCode}</div>
        </div>

        <div className="extra-div">
          <div className="indiv-div-over">Controlled: {myData.controlled}</div>
          <div className="indiv-div-over"> Phone: {myData.phone}</div>
        </div>
      </div>
      </div>
    );
  }
}

export default AiportOverviewPage;
