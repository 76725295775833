import React from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";

import { signInWithEmailandPassword } from "../../store/actions";
import { Link } from "react-router-dom";

import "../sign-in/sign-in.styles.css";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Required"),
});

const SignIn = () => {
  let history = useHistory();
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        signInWithEmailandPassword(values);
        setSubmitting(false);
        history.push("/");
      }}
    >
      {({ errors, touched }) => (
        <Form className="form-main-sign-in">
          <div className="inner-div-signup">
            <div>
              <h1 className="sign-h1-text">SIGN IN</h1>
            </div>

            <div className="email-div">
              <div htmlFor="email" className="email-text">
                Email:
              </div>
              <Field name="email" type="email" className="input-box-sign-in" />
              {errors.email && touched.email ? (
                <div className="errors-txt">{errors.email}</div>
              ) : null}
            </div>

            <div className="pass-div">
              <div htmlFor="password" className="pass-txt">
                Password:
              </div>
              <Field
                name="password"
                type="password"
                className="input-box-sign-in"
              />
              {errors.password && touched.password ? (
                <div className="errors-txt">{errors.password}</div>
              ) : null}
            </div>

            <div className="form-button ">
              <button type="submit" className="button-button">
                SUBMIT
              </button>
            </div>
            <div>
              <Link to="/forgot" className="sign-in-link">
                <h1 className="forgot-pass">Forgot Password?</h1>
              </Link>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignIn;
