import React from "react";
import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { DB } from "../../utils/firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import "./pilot-admin.styles.css";

const PilotComponent = (props) => {
  const myData = props.location.state.data;
  const [id, setId] = useState(props.location.state.data.id);
  const [oneAirport, setoneAirport] = useState(
    props.location.state.data.oneAirport
  );
  const history = useHistory();

  const [food, setFood] = useState(null);
  const [hotel, setHotel] = useState(null);
  const [uber, setUber] = useState(null);
  const [lift, setLift] = useState(null);
  const [taxi, setTaxi] = useState(null);
  const [cleanerName1, setCleanerName1] = useState(null);
  const [cleanerPhone1, setCleanerPhone1] = useState(null);
  const [cleanerName2, setCleanerName2] = useState(null);
  const [cleanerPhone2, setCleanerPhone2] = useState(null);
  const [rentalCheckbox, setRentalCheckbox] = useState([]);
  const [carLocation, setCarLocation] = useState("");
  const [icing, setIcing] = useState([]);
  const [fueling, setFueling] = useState([]);
  const [typeFuel, setTypeFuel] = useState([]);
  const [mx, setMx] = useState(null);
  const [hanger, setHanger] = useState(null);
  const [hangerSize, setHangerSize] = useState(null);
  const [clearance, setClearance] = useState(null);
  const [customs, setCustoms] = useState("");
  const [targetAddress, setTargetAddress] = useState(null);
  const [targetPhone, setTargetPhone] = useState("");
  const [walmartAddress, setWalmartAddress] = useState(null);
  const [walmartPhone, setWalmartPhone] = useState(null);
  const [otherInfo, setOtherInfo] = useState(null);
  const [huffer, setHuffer] = useState(null);

  const handleFood = (event) => {
    const target = event.target;
    const value = target.value;
    setFood(value);
  };

  const handleHotel = (event) => {
    const target = event.target;
    const value = target.value;
    setHotel(value);
  };

  const handleUber = (event) => {
    const target = event.target;
    const value = target.value;
    setUber(value);
  };
  const handleLift = (event) => {
    const target = event.target;
    const value = target.value;
    setLift(value);
  };
  const handleTaxi = (event) => {
    const target = event.target;
    const value = target.value;
    setTaxi(value);
  };

  const handleCleanerName1 = (event) => {
    const target = event.target;
    const value = target.value;
    setCleanerName1(value);
  };

  const handleCleanerPhone1 = (event) => {
    const target = event.target;
    const value = target.value;
    setCleanerPhone1(value);
  };
  const handleCleanerName2 = (event) => {
    const target = event.target;
    const value = target.value;
    setCleanerName2(value);
  };

  const handleCleanerPhone2 = (event) => {
    const target = event.target;
    const value = target.value;
    setCleanerPhone2(value);
  };

  const handleRentalCheckbox = (event) => {
    let value = event.target.value;
    let checked = event.target.checked;
    if (checked === true && rentalCheckbox.indexOf(value) === -1) {
      setRentalCheckbox([value, ...rentalCheckbox]);
    } else if (checked === false) {
      const index = rentalCheckbox.indexOf(value);
      rentalCheckbox.splice(index, 1);
      setRentalCheckbox([...rentalCheckbox]);
    }
  };

  const handleCarLocation = (event) => {
    const target = event.target;
    const value = target.value;
    setCarLocation(value);
  };

  const handleIcingCheckbox = (event) => {
    let value = event.target.value;
    let checked = event.target.checked;
    if (checked === true && icing.indexOf(value) === -1) {
      setIcing([value, ...icing]);
    } else if (checked === false) {
      const index = icing.indexOf(value);
      icing.splice(index, 1);
      setIcing([...icing]);
    }
  };

  const handleFueling = (event) => {
    let value = event.target.value;
    let checked = event.target.checked;
    if (checked === true && fueling.indexOf(value) === -1) {
      setFueling([value, ...fueling]);
    } else if (checked === false) {
      const index = fueling.indexOf(value);
      fueling.splice(index, 1);
      setFueling([...fueling]);
    }
  };

  const handleFuelType = (event) => {
    let value = event.target.value;
    let checked = event.target.checked;
    if (checked === true && typeFuel.indexOf(value) === -1) {
      setTypeFuel([value, ...typeFuel]);
    } else if (checked === false) {
      const index = typeFuel.indexOf(value);
      typeFuel.splice(index, 1);
      setTypeFuel([...typeFuel]);
    }
  };

  const handleMx = (event) => {
    const target = event.target;
    const value = target.value;
    setMx(value);
  };

  const handleHanger = (event) => {
    const target = event.target;
    const value = target.value;
    setHanger(value);
  };

  const handleHangerSize = (event) => {
    const target = event.target;
    const value = target.value;
    setHangerSize(value);
  };

  const handleClearance = (event) => {
    const target = event.target;
    const value = target.value;
    setClearance(value);
  };

  const handleCustoms = (event) => {
    const target = event.target;
    const value = target.value;
    setCustoms(value);
  };

  const handleTargetAddress = (event) => {
    const target = event.target;
    const value = target.value;
    setTargetAddress(value);
  };

  const handleTargetPhone = (event) => {
    const target = event.target;
    const value = target.value;
    setTargetPhone(value);
  };

  const handleWalmartAddress = (event) => {
    const target = event.target;
    const value = target.value;
    setWalmartAddress(value);
  };

  const handleWalmartPhone = (event) => {
    const target = event.target;
    const value = target.value;
    setWalmartPhone(value);
  };

  const handleOtherInfo = (event) => {
    const target = event.target;
    const value = target.value;
    setOtherInfo(value);
  };

  const handleHuffer = (event) => {
    const target = event.target;
    const value = target.value;
    setHuffer(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const value = event.target.value;

    const myDoc = doc(DB, `${id}`, `${oneAirport}`);

    if (food !== null) {
      updateDoc(myDoc, { food: food });
    }
    /////////////////////////////////////
    if (hotel !== null) {
      updateDoc(myDoc, { hotel: hotel });
    }
    /////////////////////////////////////
    if (uber !== null) {
      updateDoc(myDoc, { uber: uber });
    }
    ///////////////////////////////////
    if (lift !== null) {
      updateDoc(myDoc, { lift: lift });
    }
    /////////////////////////////////
    if (taxi !== null) {
      updateDoc(myDoc, { taxi: taxi });
    }
    //////////////////////////////////////////
    if (cleanerName1 !== null) {
      updateDoc(myDoc, { cleanerName1: cleanerName1 });
    }
    ////////////////////////////////////////////////////
    if (cleanerPhone1 !== null) {
      updateDoc(myDoc, { cleanerPhone1: cleanerPhone1 });
    }
    /////////////////////////////////////////////////////////////
    if (cleanerName2 !== null) {
      updateDoc(myDoc, { cleanerName2: cleanerName2 });
    }
    /////////////////////////////////////////////////////////
    if (cleanerPhone2 !== null) {
      updateDoc(myDoc, { cleanerPhone2: cleanerPhone2 });
    }
    /////////////////////////////////////////////////////
    if (rentalCheckbox.length > 0) {
      updateDoc(myDoc, { rentalCheckbox: rentalCheckbox });
    }
    ///////////////////////////////////////////////////////
    if (carLocation !== null) {
      updateDoc(myDoc, { carLocation: carLocation });
    }
    ////////////////////////////////////////////////
    if (icing.length > 0) {
      updateDoc(myDoc, { icing: icing });
    }
    /////////////////////////////////////////////////////
    if (fueling.length > 0) {
      updateDoc(myDoc, { fueling: fueling });
    }
    ////////////////////////////////////
    if (typeFuel.length > 0) {
      updateDoc(myDoc, { typeFuel: typeFuel });
    }
    ///////////////////////////////////////////
    if (mx !== null) {
      updateDoc(myDoc, { mx: mx });
    }
    ////////////////////////////////////
    if (hanger !== null) {
      updateDoc(myDoc, { hanger: hanger });
    }
    ////////////////////////////////////////
    if (hangerSize !== null) {
      updateDoc(myDoc, { hangerSize: hangerSize });
    }
    ////////////////////////////////////////////
    if (clearance !== null) {
      updateDoc(myDoc, { clearance: clearance });
    }
    //////////////////////////////////////////
    if (customs !== null) {
      updateDoc(myDoc, { customs: customs });
    }
    ////////////////////////////////////////
    if (targetAddress !== null) {
      updateDoc(myDoc, { targetAddress: targetAddress });
    }
    //////////////////////////////////////////
    if (targetPhone !== null) {
      updateDoc(myDoc, { targetPhone: targetPhone });
    }
    ////////////////////////////////////////////////
    if (walmartAddress !== null) {
      updateDoc(myDoc, { walmartAddress: walmartAddress });
    }
    ///////////////////////////////////////////////
    if (walmartPhone !== null) {
      updateDoc(myDoc, { walmartPhone: walmartPhone });
    }
    /////////////////////////////////////////////////
    if (huffer !== null) {
      updateDoc(myDoc, { huffer: huffer });
    }
    //////////////////////////////////////////////
    if (otherInfo !== null) {
      updateDoc(myDoc, { otherInfo: otherInfo });
    }

    toast.success("YOUR INFO WAS ADDED", {
      position: "bottom-center",
      autoClose: 3000,
      closeOnClick: true,
      draggable: true,
      className: "toast-signup-containter",
    });
  };

  const checkNonAdminPage = (event) => {
    let path = `/states/${id}/${oneAirport}/pilotinfo`;
    history.push({
      pathname: path,
      state: { id: id, oneAirport: oneAirport },
    });
  };

  return (
    <div className="pilot-main-div">
      {/* First Div */}
      <div>
        <h1>
          Hey Guys Check here to see What Other Aviators Have already Added
        </h1>
        <h1>You might Overwrite What they have already done be Careful</h1>
        <button onClick={checkNonAdminPage} className="button">
          Click Here to See whats been added already
        </button>
      </div>
      <div>
        <h1>{myData.icao}</h1>
      </div>
      {/* Start of Food */}
      <div className="food">
        <h1>Food Proximity</h1>
        <form>
          <input
            type="radio"
            id="5min"
            name="food"
            value="5 Minutes Away"
            onChange={handleFood}
          />
          <label htmlFor="5min">Less than 5 Minutes</label>

          <input
            type="radio"
            id="10min"
            name="food"
            value="10 Minutes Away"
            onChange={handleFood}
          />
          <label htmlFor="10min">More than 10 Minutes</label>

          <input
            type="radio"
            id="20min"
            name="food"
            value="20 Minutes Away"
            onChange={handleFood}
          />
          <label htmlFor="20min">More than 20 Minutes</label>

          <input
            type="radio"
            id="way2far"
            name="food"
            value="Way To Far"
            onChange={handleFood}
          />
          <label htmlFor="way2far">Way to Far Order A Head of Time</label>
        </form>
      </div>
      {/* Start of Hotels */}
      <div className="hotels">
        <h1>Hotels Proximity</h1>
        <form>
          <input
            type="radio"
            id="5hotel"
            name="hotels"
            value="5 Minutes Away"
            onChange={handleHotel}
          />
          <label htmlFor="5hotel">Less than 5 Minutes</label>

          <input
            type="radio"
            id="10hotel"
            name="hotels"
            value="10 Minutes Away"
            onChange={handleHotel}
          />
          <label htmlFor="10hotel">More than 10 Minutes</label>

          <input
            type="radio"
            id="20hotel"
            name="hotels"
            value="20 Minutes Away"
            onChange={handleHotel}
          />
          <label htmlFor="20hotel">More than 20 Minutes</label>

          <input
            type="radio"
            id="way2farhotel"
            name="hotels"
            value="Way to Far"
            onChange={handleHotel}
          />
          <label htmlFor="way2farhotel">Your Going to Be Driving</label>
        </form>
      </div>
      {/* Start of RideShare/Taxi */}
      <div className="ride">
        <h1>Ride Share/Taxi</h1>
        <div>
          <h1>Uber</h1>
          <form>
            <input
              type="radio"
              id="awsomeUber"
              name="uber"
              value="Uber is Awesome Here"
              onChange={handleUber}
            />
            <label htmlFor="awsomeUber">Awsome No Problems</label>

            <input
              type="radio"
              id="okayUber"
              name="uber"
              value="Uber is just Okay Here"
              onChange={handleUber}
            />
            <label htmlFor="okayUber">Uber is just Okay Here</label>

            <input
              type="radio"
              id="noWayUber"
              name="uber"
              value="No way Not going to happen"
              onChange={handleUber}
            />
            <label htmlFor="noWayUber">No Way this is going to happen</label>
          </form>
        </div>

        <div>
          <h1>Lift</h1>
          <form>
            <input
              type="radio"
              id="awsomeLift"
              name="lift"
              value="Lift is awesome here"
              onChange={handleLift}
            />
            <label htmlFor="awsomeLift">Awsome No Problems</label>

            <input
              type="radio"
              id="okayLift"
              name="lift"
              value="Lift is just okay here"
              onChange={handleLift}
            />
            <label htmlFor="okayLift">Lift is just Okay Here</label>

            <input
              type="radio"
              id="noWayLift"
              name="lift"
              value="No way Not going to happen"
              onChange={handleLift}
            />
            <label htmlFor="noWayLift">No Way this is going to happen</label>
          </form>
        </div>

        <div>
          <h1>Taxi</h1>
          <p>Enter in the Name of a Good Taxi Company</p>
          <p>You can Leave this Blank</p>
          <input
            type="text"
            id="taxi"
            name="taxi"
            onChange={handleTaxi}
            className="taxi-input"
          />
        </div>
      </div>
      {/* Start Aircraft Cleaner */}
      <div className="clean">
        <h1>Aircraft Cleaners</h1>
        <p>Enter in the Name and contact of Good Cleaner</p>
        <label htmlFor="cleanerName1">Name</label>
        <input
          type="text"
          id="cleanerName1"
          name="cleanerName1"
          onChange={handleCleanerName1}
          className="input-cleaner"
        />
        <label htmlFor="cleanerPhone1">Phone</label>
        <input
          type="text"
          id="cleanerPhone1"
          name="cleanerPhone1"
          onChange={handleCleanerPhone1}
          className="input-cleaner"
        />

        <p>If You have another Contact</p>
        <label htmlFor="cleanerName2">Name</label>
        <input
          type="text"
          id="cleanerName2"
          name="cleanerName2"
          onChange={handleCleanerName2}
          className="input-cleaner"
        />
        <label htmlFor="cleanerPhone2">Phone</label>
        <input
          type="text"
          id="cleanerPhone2"
          name="cleanerPhone2"
          onChange={handleCleanerPhone2}
          className="input-cleaner"
        />
      </div>
      {/* Start Rental Cars */}
      <div className="rent">
        <h1>Rental Cars</h1>
        <div>
          <h1>Who is Renting Cars Here?</h1>
          <form>
            <input
              type="checkbox"
              id="national"
              name="national"
              value="National"
              onChange={handleRentalCheckbox}
            />
            <label htmlFor="national">National</label>

            <input
              type="checkbox"
              id="alamo"
              name="alamo"
              value="Alamo"
              onChange={handleRentalCheckbox}
            />
            <label htmlFor="alamo">Alamo</label>

            <input
              type="checkbox"
              id="avis"
              name="avis"
              value="Avis"
              onChange={handleRentalCheckbox}
            />
            <label htmlFor="avis">Avis</label>

            <input
              type="checkbox"
              id="hertz"
              name="hertz"
              value="Hertz"
              onChange={handleRentalCheckbox}
            />
            <label htmlFor="hertz">Hertz</label>

            <input
              type="checkbox"
              id="enterprise"
              name="enterprise"
              value="Enterprise"
              onChange={handleRentalCheckbox}
            />
            <label htmlFor="enterprise">Enterprise</label>

            <input
              type="checkbox"
              id="budget"
              name="budget"
              value="Budget"
              onChange={handleRentalCheckbox}
            />
            <label htmlFor="budget">Budget</label>

            <input
              type="checkbox"
              id="goRentals"
              name="goRentals"
              value="Go Rentals"
              onChange={handleRentalCheckbox}
            />
            <label htmlFor="goRentals">Go Rentals</label>
          </form>
        </div>

        <div>
          <h1>Rental Car Location</h1>
          <form>
            <input
              type="radio"
              id="atFbo"
              name="rentalLocation"
              value="You can get one at the FBO"
              onChange={handleCarLocation}
            />
            <label htmlFor="atFbo">The FBO has Cars</label>

            <input
              type="radio"
              id="atTerm"
              name="rentalLocation"
              value="Best bet is at the Terminal"
              onChange={handleCarLocation}
            />
            <label htmlFor="atTerm">Best Bet is the Terminal</label>

            <input
              type="radio"
              id="offField"
              name="rentalLocation"
              value="None on the Airport"
              onChange={handleCarLocation}
            />
            <label htmlFor="offField">Going Off Feild for a Car</label>
          </form>
        </div>
      </div>
      {/* Starting Icing */}
      <div className="ice">
        <h1>Icing Capabilities</h1>
        <form>
          <input
            type="checkbox"
            id="Type1"
            name="Type1"
            value="Type1"
            onChange={handleIcingCheckbox}
          />
          <label htmlFor="Type1">Type 1</label>

          <input
            type="checkbox"
            id="Type2"
            name="Type2"
            value="Type2"
            onChange={handleIcingCheckbox}
          />
          <label htmlFor="Type2">Type 2</label>

          <input
            type="checkbox"
            id="Type3"
            name="Type3"
            value="Type3"
            onChange={handleIcingCheckbox}
          />
          <label htmlFor="Type3">Type 3</label>

          <input
            type="checkbox"
            id="Type4"
            name="Type4"
            value="Type4"
            onChange={handleIcingCheckbox}
          />
          <label htmlFor="Type4">Type 4</label>

          <input
            type="checkbox"
            id="none"
            name="none"
            value="None"
            onChange={handleIcingCheckbox}
          />
          <label htmlFor="none">None Here</label>
        </form>
      </div>
      {/* Starting Fuel */}
      <div className="fuel">
        <h1>Fueling Services</h1>
        <form>
          <input
            type="checkbox"
            id="selfFuel"
            name="selfFuel"
            value="Self Fueling Available"
            onChange={handleFueling}
          />
          <label htmlFor="selfFuel">Self Fueling Available</label>

          <input
            type="checkbox"
            id="regular"
            name="regular"
            value="Standard day the FBO will come Fuel you up"
            onChange={handleFueling}
          />
          <label htmlFor="regular">FBO has the Fuel</label>

          <input
            type="checkbox"
            id="noFuel"
            name="noFuel"
            value="Your not getting Fuel Here"
            onChange={handleFueling}
          />
          <label htmlFor="noFuel">No FUEL HERE</label>
        </form>
      </div>
      {/* Starting Fuel Type */}
      <div className="fuel">
        <h1>Fueling Types</h1>
        <form>
          <input
            type="checkbox"
            id="lowLead"
            name="lowLead"
            value="Low Lead"
            onChange={handleFuelType}
          />
          <label htmlFor="lowLead">100 LL</label>

          <input
            type="checkbox"
            id="jetFuel"
            name="jetFuel"
            value="Jet Fuel"
            onChange={handleFuelType}
          />
          <label htmlFor="jetFuel">Jet Fuel</label>
        </form>
      </div>
      {/* MX Available */}
      <div className="mx">
        <h1>MX Available</h1>
        <form>
          <input
            type="radio"
            id="canJet"
            name="canJet"
            value="Can work on Jets"
            onChange={handleMx}
          />
          <label htmlFor="canJet">Can work on Jets</label>

          <input
            type="radio"
            id="cantJet"
            name="cantJet"
            value="Can NOT work on Jets"
            onChange={handleMx}
          />
          <label htmlFor="cantJet">Can't Work on Jets</label>
        </form>
      </div>

      {/* Starting Hanger */}
      <div className="hanger">
        <h1>Hanger</h1>
        <form>
          <input
            type="radio"
            id="hangerGood"
            name="hanger"
            value="Awsome No Problems getting a hanger"
            onChange={handleHanger}
          />
          <label htmlFor="hangerGood">Awsome No Problems</label>

          <input
            type="radio"
            id="hangerCall"
            name="hanger"
            value="If you want a Hanger Suggest you Call"
            onChange={handleHanger}
          />
          <label htmlFor="hangerCall">You Need to call ahead</label>

          <input
            type="radio"
            id="noWayHanger"
            name="hanger"
            value="Either the Hanger Doesn't Exist or good Luck"
            onChange={handleHanger}
          />
          <label htmlFor="noWayHanger">No Hanger</label>
        </form>
        <div className="hanger">
          <h1>Hanger Size</h1>
          <form>
            <input
              type="radio"
              id="hangerCJ"
              name="hangerSize"
              value="Hanger Will fit CJ"
              onChange={handleHangerSize}
            />
            <label htmlFor="hangerCJ">Will Fit CJ</label>

            <input
              type="radio"
              id="hanger560XL"
              name="hangerSize"
              value="Hanger Will fit 560XL"
              onChange={handleHangerSize}
            />
            <label htmlFor="hanger560XL">Will fit 560XL</label>

            <input
              type="radio"
              id="hangerChallenger"
              name="hangerSize"
              value="Hanger Will Fit Challenger"
              onChange={handleHangerSize}
            />
            <label htmlFor="hangerChallenger">Will Fit Challenger</label>

            <input
              type="radio"
              id="hangerGulfstream"
              name="hangerSize"
              value="Hanger Will Fit Gulfstream"
              onChange={handleHangerSize}
            />
            <label htmlFor="hangerGulfstream">Will Fit Gulfstream</label>
          </form>
        </div>
      </div>
      {/* Starting Clearance */}
      <div className="clearance">
        <h1>Clearance Options</h1>
        <p>Pick the Best Option</p>
        <p>If it is a Number put it in Other</p>
        <form>
          <input
            type="radio"
            id="fss"
            name="clearance"
            value="FSS"
            onChange={handleClearance}
          />
          <label htmlFor="fss">Flight Service Phone</label>

          <input
            type="radio"
            id="rco"
            name="clearance"
            value="RCO"
            onChange={handleClearance}
          />
          <label htmlFor="rco">RCO</label>

          <input
            type="radio"
            id="approach"
            name="clearance"
            value="Approach Control"
            onChange={handleClearance}
          />
          <label htmlFor="approach">Approach Control</label>

          <input
            type="radio"
            id="phoneNumber"
            name="clearance"
            value="Phone Number see other for the number"
            onChange={handleClearance}
          />
          <label htmlFor="phoneNumber">Best Way is to Call</label>

          <input
            type="radio"
            id="ground"
            name="clearance"
            value="Normal Day Ground or Clearance DL"
            onChange={handleClearance}
          />
          <label htmlFor="ground">Contact Ground/Clearance Delievery</label>
        </form>
      </div>
      {/* Starting Customs */}
      <div className="customs">
        <h1>Customs Options</h1>
        <p>Please put details in Other</p>
        <form>
          <input
            type="radio"
            id="atFbo"
            name="customs"
            value="You can clear Customs at FBO"
            onChange={handleCustoms}
          />
          <label htmlFor="atFbo">They will come to FBO</label>

          <input
            type="radio"
            id="onField"
            name="customs"
            value="Customs is on the Field "
            onChange={handleCustoms}
          />
          <label htmlFor="onField">On the Field</label>

          <input
            type="radio"
            id="noCustoms"
            name="customs"
            value="No Customs Here make Arrangements"
            onChange={handleCustoms}
          />
          <label htmlFor="noCustoms">No Customs Here</label>
        </form>
      </div>
      {/* Starting Target Store */}
      <div className="target">
        <h1>Nearest Target</h1>
        <label htmlFor="storeTarget">Target Address</label>
        <input
          type="text"
          id="storeTarget"
          name="storeTarget"
          onChange={handleTargetAddress}
          className="input-target"
        />
        <label htmlFor="storeTargetPhone">Phone Number</label>
        <input
          type="text"
          id="storeTargetPhone"
          name="storeTargetPhone"
          onChange={handleTargetPhone}
          className="input-target"
        />
      </div>
      {/* Starting Walmart */}
      <div className="walmart">
        <h1>Nearest Walmart</h1>
        <label htmlFor="storeWalmart">Walmart Address</label>
        <input
          type="text"
          id="storeWalmart"
          name="storeWalmart"
          onChange={handleWalmartAddress}
          className="input-target"
        />
        <label htmlFor="storeWalmartPhone">Phone Number</label>
        <input
          type="text"
          id="storeWalmartPhone"
          name="storeWalmartPhone"
          onChange={handleWalmartPhone}
          className="input-target"
        />
      </div>
      {/* Starting Huffer Cart */}
      <div className="huffer">
        <h1>Huffer Cart</h1>
        <form>
          <input
            type="radio"
            id="FboHasHuffer"
            name="huffer"
            value="FBO has a Huffer Cart"
            onChange={handleHuffer}
          />
          <label htmlFor="FboHasHuffer">The FBO has a Huffer Cart</label>

          <input
            type="radio"
            id="HufferontheFeild"
            name="huffer"
            value="Huffer Cart is located somewhere on the Field"
            onChange={handleHuffer}
          />
          <label htmlFor="HufferontheFeild">Huffer Cart on the Field</label>

          <input
            type="radio"
            id="noHuffer"
            name="huffer"
            value="No Huffer Cart here"
            onChange={handleHuffer}
          />
          <label htmlFor="noHuffer">No Huffer Cart on this Field</label>
        </form>
      </div>
      {/* Starting Other */}
      <div>
        <h1>Other Information</h1>
        <textarea
          cols="100"
          rows="100"
          id="otherInfo"
          name="otherInfo"
          className="otherInfo"
          onChange={handleOtherInfo}
        />
      </div>
      <button className="button" type="submit" onClick={handleSubmit}>
        On Submit
      </button>
      {/* Closing Div */}
    </div>
  );
};

export default PilotComponent;
