import { AUTH_USER } from "../types/types";

export default function (state = null, action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...action.payload };
    default:
      return state;
  }
}
