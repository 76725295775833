import React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { DB } from "../../utils/firebase";

import "./pilot-not-admin.styles.css";

const PilotNotAdminComponent = (id, oneAirport) => {
  const history = useHistory();

  const adminID = history.location.state.id;
  const adminOneAirport = history.location.state.oneAirport;

  const [food, setFood] = useState("");
  const [hotel, setHotel] = useState("");
  const [uber, setUber] = useState("");
  const [lift, setLift] = useState("");
  const [taxi, setTaxi] = useState("");
  const [cleanerName1, setCleanerName1] = useState("");
  const [cleanerPhone1, setCleanerPhone1] = useState("");
  const [cleanerName2, setCleanerName2] = useState("");
  const [cleanerPhone2, setCleanerPhone2] = useState("");
  const [rentalCheckbox, setRentalCheckbox] = useState([]);
  const [carLocation, setCarLocation] = useState("");
  const [icing, setIcing] = useState([]);
  const [fueling, setFueling] = useState([]);
  const [typeFuel, setTypeFuel] = useState([]);
  const [mx, setMx] = useState("");
  const [hanger, setHanger] = useState("");
  const [hangerSize, setHangerSize] = useState("");
  const [clearance, setClearance] = useState("");
  const [customs, setCustoms] = useState("");
  const [targetAddress, setTargetAddress] = useState("");
  const [targetPhone, setTargetPhone] = useState("");
  const [walmartAddress, setWalmartAddress] = useState("");
  const [walmartPhone, setWalmartPhone] = useState("");
  const [otherInfo, setOtherInfo] = useState("");
  const [huffer, setHuffer] = useState("");

  useEffect(async () => {
    let id = "";
    let oneAirport = "";

    if (adminID) {
      id = adminID;
    } else {
      id = history.location.state.data.id;
    }

    if (adminOneAirport) {
      oneAirport = adminOneAirport;
    } else {
      oneAirport = history.location.state.data.oneAirport;
    }

    const docRef = doc(DB, id, oneAirport);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    if (data.food !== null) {
      setFood(data.food);
    }
    if (data.food == undefined || null || data.food == "") {
      setFood("No Data Here Yet");
    }

    if (data.hotel !== null) {
      setHotel(data.hotel);
    }
    if (data.hotel == undefined || null || data.hotel == "") {
      setHotel("No Data Here Yet");
    }

    if (data.uber !== null) {
      setUber(data.uber);
    }
    if (data.uber == undefined || null || data.uber == "") {
      setUber("No Data Here Yet");
    }

    if (data.lift !== null) {
      setLift(data.lift);
    }
    if (data.lift == undefined || null || data.lift == "") {
      setLift("No Data Here Yet");
    }

    if (data.taxi !== null) {
      setTaxi(data.taxi);
    }
    if (data.taxi == undefined || null || data.taxi == "") {
      setTaxi("No Data Here Yet");
    }

    if (data.cleanerName1 !== null) {
      setCleanerName1(data.cleanerName1);
    }
    if (data.cleanerName1 == undefined || null || data.cleanerName1 == "") {
      setCleanerName1("No Data Here Yet");
    }

    if (data.cleanerPhone1 !== null) {
      setCleanerPhone1(data.cleanerPhone1);
    }
    if (data.cleanerPhone1 == undefined || null || data.cleanerPhone1 == "") {
      setCleanerPhone1("No Data Here Yet");
    }

    if (data.cleanerName2 !== null) {
      setCleanerName2(data.cleanerName2);
    }
    if (data.cleanerName2 == undefined || null || data.cleanerName2 == "") {
      setCleanerName2("No Data Here Yet");
    }

    if (data.cleanerPhone2 !== null) {
      setCleanerPhone2(data.cleanerPhone2);
    }
    if (data.cleanerPhone2 == undefined || null || data.cleanerPhone2 == "") {
      setCleanerPhone2("No Data Here Yet");
    }

    if (data.rentalCheckbox !== null || undefined) {
      setRentalCheckbox(data.rentalCheckbox);
    }

    if (data.carLocation !== null) {
      setCarLocation(data.carLocation);
    }
    if (data.carLocation == undefined || null || data.carLocation == "") {
      setCarLocation("No Data Here Yet");
    }

    if (data.icing !== null) {
      setIcing(data.icing);
    }

    if (data.fueling !== null) {
      setFueling(data.fueling);
    }

    if (data.typeFuel !== null) {
      setTypeFuel(data.typeFuel);
    }

    if (data.mx !== null) {
      setMx(data.mx);
    }
    if (data.mx == undefined || null || data.mx == "") {
      setMx("No Data Here Yet");
    }

    if (data.hanger !== null) {
      setHanger(data.hanger);
    }
    if (data.hanger == undefined || null || data.hanger == "") {
      setHanger("No Data Here Yet");
    }

    if (data.hangerSize !== null) {
      setHangerSize(data.hangerSize);
    }
    if (data.hangerSize == undefined || null || data.hangerSize == "") {
      setHangerSize("No Data Here Yet");
    }

    if (data.clearance !== null) {
      setClearance(data.clearance);
    }
    if (data.clearance == undefined || null || data.clearance == "") {
      setClearance("No Data Here Yet");
    }

    if (data.customs !== null) {
      setCustoms(data.customs);
    }
    if (data.customs == undefined || null || data.customs == "") {
      setCustoms("No Data Here Yet");
    }

    if (data.targetAddress !== null) {
      setTargetAddress(data.targetAddress);
    }
    if (data.targetAddress == undefined || null || data.targetAddress == "") {
      setTargetAddress("No Data Here Yet");
    }

    if (data.targetPhone !== null) {
      setTargetPhone(data.targetPhone);
    }
    if (data.targetPhone == undefined || null || data.targetPhone == "") {
      setTargetPhone("No Data Here Yet");
    }

    if (data.walmartAddress !== null) {
      setWalmartAddress(data.walmartAddress);
    }
    if (data.walmartAddress == undefined || null || data.walmartAddress == "") {
      setWalmartAddress("No Data Here Yet");
    }

    if (data.walmartPhone !== null) {
      setWalmartPhone(data.walmartPhone);
    }
    if (data.walmartPhone == undefined || null || data.walmartPhone == "") {
      setWalmartPhone("No Data Here Yet");
    }

    if (data.huffer !== null) {
      setHuffer(data.huffer);
    }
    if (data.huffer == undefined || null || data.huffer == "") {
      setHuffer("No Data Here Yet");
    }

    if (data.otherInfo !== null) {
      setOtherInfo(data.otherInfo);
    }
    if (data.otherInfo == undefined || null || data.otherInfo == "") {
      setOtherInfo("No Data Here Yet");
    }
  }, []);

  return (
    <div className="ptnotadmin-total-div">
    <div className="ptnotadmin-main-div">
      {/* FOOD */}
      <div className="ptnotfood">
        <div>
          <h1 className="ptnoth1">FOOD</h1>
        </div>
        <div className="pptnotanswer">{food}</div>
      </div>
      {/* HOTEL */}
      <div className="ptnothotel">
        <div>
          <h1 className="ptnoth1">HOTEL</h1>
        </div>
        <div className="pptnotanswer">{hotel}</div>
      </div>
      {/* UBER */}
      <div className="ptnotuber">
        <div>
          <h1 className="ptnoth1">UBER</h1>
        </div>
        <div className="pptnotanswer">{uber}</div>
      </div>
      {/* LIFT */}
      <div className="ptnotlift">
        <div>
          <h1 className="ptnoth1">LIFT</h1>
        </div>
        <div className="pptnotanswer">{lift}</div>
      </div>
      {/* TAXI */}
      <div className="ptnottaxi">
        <div>
          <h1 className="ptnoth1">TAXI</h1>
        </div>
        <div className="pptnotanswer">{taxi}</div>
      </div>
      {/* cleaner name 1 */}
      <div className="ptnotc1name">
        <div>
          <h1 className="ptnoth1">CLEANER 1 NAME</h1>
        </div>
        <div className="pptnotanswer">{cleanerName1}</div>
      </div>
      {/* cleaner phone 1 */}
      <div className="ptnotc1phone">
        <div>
          <h1 className="ptnoth1">CLEANER 1 PHONE</h1>
        </div>
        <div className="pptnotanswer">{cleanerPhone1}</div>
      </div>
      {/* cleaner name 2 */}
      <div className="ptnotc2name">
        <div>
          <h1 className="ptnoth1">CLEANER 2 NAME</h1>
        </div>
        <div className="pptnotanswer">{cleanerName2}</div>
      </div>
      {/* cleaner phone 2 */}
      <div className="ptnotc2phone">
        <div>
          <h1 className="ptnoth1">CLEANER 2 PHONE</h1>
        </div>
        <div className="pptnotanswer">{cleanerPhone2}</div>
      </div>
      {/* renatal car providers */}
      <div className="ptnotrentalcars">
        <div>
          <h1 className="ptnoth1">RENTAL CAR PROVIDERS</h1>
        </div>
        <div className="pptnotanswer">
          {rentalCheckbox !== undefined || null
            ? rentalCheckbox.map((x) => x + " ")
            : "No Data Here Yet"}
        </div>
      </div>
      {/* renatal car location */}
      <div className="ptnotrentallocation">
        <div>
          <h1 className="ptnoth1">RENTAL CAR LOCATION</h1>
        </div>
        <div className="pptnotanswer">{carLocation}</div>
      </div>
      {/* icing */}
      <div className="ptnoticing">
        <div>
          <h1 className="ptnoth1">ICING</h1>
        </div>
        <div className="pptnotanswer">
          {icing !== undefined || null
            ? icing.map((x) => x + " ")
            : "No Data Here Yet"}
        </div>
      </div>
      {/* Fueling */}
      <div className="ptnotfueling">
        <div>
          <h1 className="ptnoth1">FUELING OPTIONS</h1>
        </div>
        <div className="pptnotanswer">
          {" "}
          {fueling !== undefined || null
            ? fueling.map((x) => x + " ")
            : "No Data Here Yet"}
        </div>
      </div>
      {/* Fueling types*/}
      <div className="ptnotfuelingtypes">
        <div>
          <h1 className="ptnoth1">FUELING TYPES</h1>
        </div>
        <div className="pptnotanswer">
          {" "}
          {typeFuel !== undefined || null
            ? typeFuel.map((x) => x + " ")
            : "No Data Here Yet"}
        </div>
      </div>
      {/* mx*/}
      <div className="ptnotmx">
        <div>
          <h1 className="ptnoth1">CAN WE GET MX</h1>
        </div>
        <div className="pptnotanswer">{mx}</div>
      </div>
      {/* hanger */}
      <div className="ptnothanger">
        <div>
          <h1 className="ptnoth1">HANGER AVAILABILITY</h1>
        </div>
        <div className="pptnotanswer">{hanger}</div>
      </div>
      {/* hanger size */}
      <div className="ptnothangersize">
        <div>
          <h1 className="ptnoth1">HANGER SIZE</h1>
        </div>
        <div className="pptnotanswer">{hangerSize}</div>
      </div>
      {/* clearance */}
      <div className="ptnotclearance">
        <div>
          <h1 className="ptnoth1">CLEARANCE</h1>
        </div>
        <div className="pptnotanswer">{clearance}</div>
      </div>
      {/* customs */}
      <div className="ptnotcustoms">
        <div>
          <h1 className="ptnoth1">CUSTOMS</h1>
        </div>
        <div className="pptnotanswer">{customs}</div>
      </div>
      {/* target add */}
      <div className="ptnottargetadd">
        <div>
          <h1 className="ptnoth1">TARGET ADDRESS</h1>
        </div>
        <div className="pptnotanswer">{targetAddress}</div>
      </div>
      {/* target phone */}
      <div className="ptnottargetphone">
        <div>
          <h1 className="ptnoth1">TARGET PHONE</h1>
        </div>
        <div className="pptnotanswer">{targetPhone}</div>
      </div>
      {/* walmart add */}
      <div className="ptnotwalmartadd">
        <div>
          <h1 className="ptnoth1">WALMART ADDRESS</h1>
        </div>
        <div className="pptnotanswer">{walmartAddress}</div>
      </div>
      {/* walmart phone */}
      <div className="ptnotwalmartphone">
        <div>
          <h1 className="ptnoth1">WALMART PHONE</h1>
        </div>
        <div className="pptnotanswer">{walmartPhone}</div>
      </div>
      {/* HUFFER */}
      <div className="ptnothuffer">
        <div>
          <h1 className="ptnoth1">HUFFER CART</h1>
        </div>
        <div className="pptnotanswer">{huffer}</div>
      </div>
      {/* OTHER */}
      <div className="ptnotother">
        <div>
          <h1 className="ptnoth1">OTHER</h1>
        </div>
        <div className="pptnotanswer">{otherInfo}</div>
      </div>
    </div>
    </div>
  );
};

export default PilotNotAdminComponent;
