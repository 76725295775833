import React from "react";

import "./footer.styles.css";

const Footer = () => {
  return (
    <div className="footer-div">
      <h1 className="footer-h1">
        Well Hello and Welcome,
        <br />
        When you Sign Up, and I see a new user I will reach out and verify a
        work email for you.
        <br />
        Any Questions can be directed to poskittapps@yahoo.com.
        <br />
        However, if a work email is not available, I will ask you to verify your
        a pilot in some way or form, ex Medical certifiate.
        <br />
        For my Admins or people who want to be Admins I have a PDF for all the
        information to be added
        <br />
        This website is for FLIGHT CREW ONLY.
      </h1>
    </div>
  );
};

export default Footer;
