import React from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import "../sign-in/sign-in.styles.css";

const ForgotPassSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required"),
});

const ForgotPass = () => {
  let history = useHistory();
  const auth = getAuth();
  return (
    <div className="total-youhavetosignin">
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={ForgotPassSchema}
      onSubmit={(values, { setSubmitting }) => {
        sendPasswordResetEmail(auth, values.email)
          .then(() => {})
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
          });
        setSubmitting(false);
        history.replace("/signin");
      }}
    >
      {({ errors, touched }) => (
        <Form className="form-main-sign-in">
          <div className="inner-div-signup">
            <div>
              <h1 className="sign-h1-text">Forgot Password?</h1>
            </div>

            <div className="email-div">
              <div htmlFor="email" className="email-text">
                Email:
              </div>
              <Field name="email" type="email" className="input-box-sign-in" />
              {errors.email && touched.email ? (
                <div className="errors-txt">{errors.email}</div>
              ) : null}
            </div>

            <div className="form-button ">
              <button type="submit" className="button-button">
                SUBMIT
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
    </div>
  );
};

export default ForgotPass;
