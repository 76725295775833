import React from "react";

import "../card/card.styles.css";

const CardComponent = (props) => {
  return (
    <div
      key={props.i}
      className={"inner-card-div"}
      id={props.controlled == "true" ? "controlled" : "uncontrolled"}
    >
      {props.name}
    </div>
  );
};

export default CardComponent;
