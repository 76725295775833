import React from "react";
import { withRouter } from "react-router";
import { DB } from "../../utils/firebase";
import { collection, getDocs } from "firebase/firestore";
import CardComponent from "../card/card.component";
import ClipLoader from "react-spinners/ClipLoader";
import "../states-code/state-code.styles.css";
import "../card/card.styles.css";
import { Link } from "react-router-dom";

const override = {
  display: "block",
  margin: "0 auto",
  marginTop: "10%",
  borderColor: "black",
};

class StateCodes extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ id: id });

    this.setState({ pathName: this.props.match.url });

    const myArray = [];
    const conArray = [];
    const querySnapshot = getDocs(collection(DB, id)).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.id;
        const control = doc.data().Controlled.toString();
        myArray.push(data);
        conArray.push(control);
      });
      this.setState({ airportCodes: myArray });
      this.setState({ controlled: conArray });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      airportCodes: false,
      searchArray: "",
      controlled: [],
      pathName: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, name) {
    e.preventDefault();
    name = e.target.value.toLocaleUpperCase();
    const filteredAirports = this.state.airportCodes.filter((airports) => {
      return airports.toLocaleUpperCase().includes(name);
    });
    this.setState({ searchArray: filteredAirports });
  }

  render() {
    let i = 0;
    return this.state.airportCodes ? (
      <div key={i++} className="state-main-div">
        <div className="code-header-div" key={i++}>
          {this.state.id.toLocaleUpperCase()}
          <input
            className="input-box"
            type="text"
            onChange={this.handleChange}
          />
        </div>
        <div className="card-div" key={i++}>
          {this.state.searchArray
            ? this.state.searchArray.map((airports, i) => {
                return (
                  <Link
                    key={i}
                    className="state-link"
                    to={{
                      pathname: `${this.state.pathName}/${airports}`,
                    }}
                  >
                    <CardComponent
                      key={i++}
                      name={airports}
                      controlled={
                        this.state.controlled[
                          this.state.airportCodes.indexOf(airports)
                        ]
                      }
                    />
                  </Link>
                );
              })
            : this.state.airportCodes.map((airports, i) => {
                return (
                  <Link
                    key={i}
                    className="state-link"
                    to={{
                      pathname: `${this.state.pathName}/${airports}`,
                    }}
                  >
                    <CardComponent
                      key={i}
                      name={airports}
                      controlled={this.state.controlled[i]}
                    />
                  </Link>
                );
              })}
        </div>
      </div>
    ) : (
      <ClipLoader
        cssOverride={override}
        size={300}
        color={"#00000"}
        speedMultiplier={1}
        loading={!this.state.airportCodes}
      />
    );
  }
}

export default withRouter(StateCodes);
