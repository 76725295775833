import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import "./google.map.styles.css";

const containerStyle = {
  width: "97%",
  height: "500px",
  border: "5px ridge #1C6EA4",
  borderRadius: "5px",
  marginTop: "20px",
  marginLeft: "auto",
  marginRight: "auto",
};

function GMViewer(props) {
  const myData = props.location.state.data;

  const airport = myData.icao;
  const finalLat = myData.decLat;
  const finalLong = myData.decLng;

  const fLat = parseFloat(finalLat);
  const fLong = parseFloat(finalLong);

  const center = {
    lat: fLat,
    lng: fLong,
  };

  return (
    <div className="google-total">
    <LoadScript
      id="script-laoder"
      googleMapsApiKey="AIzaSyABHsfrBeGJIxI7Kh4NiqoCKVhqWnqeAUI"
      libraries={["places"]}
    >
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        <Marker position={center} label={`${airport}`} />
      </GoogleMap>
    </LoadScript>
    </div>
  );
}

export default React.memo(GMViewer);
