import React from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";

import "../hotel-noreview/ht-noreview.styles.css";
import "./comment.rv.styles.css";
import { useState } from "react";
import { DB } from "../../utils/firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";

const ReviewSchema = Yup.object().shape({
  comment: Yup.string().required("Required"),
});

const CommentReviewComponent = (props) => {
  const history = useHistory();
  const id = props.id;
  const oneAirport = props.oneAirport;
  const displayName = props.displayName;
  const snapShot = props.snapShot;
  const indexing = history.location.state.indexing;
  const commentLength = history.location.state.comments;

  let [isLoading, setLoading] = useState(true);
  const [comment, setComment] = useState("");

  useEffect(async () => {
    if (snapShot !== null && indexing) {
      const docRef = doc(DB, id, oneAirport, "comments", indexing.toString());
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let comment = docSnap.data().comment;

        setComment(comment);
      }
    }

    setLoading(false);
  }, [isLoading]);

  return (
    <div className="comment-total-div">
      <Formik
        enableReinitialize
        initialValues={{
          comment: comment ? comment : "",
        }}
        validationSchema={ReviewSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          ////////////FUNCTION add First /////////////
          const onAddFirstReview = async () => {
            if (snapShot === null) {
              /////////////////////////////////////
              ///Add First Review/////////////////
              await setDoc(doc(DB, id, oneAirport, "comments", "1"), {
                index: 1,
                displayName: displayName,
                createdAt: new Date(),
                comment: values.comment,
              });
            }
            ////////////////////////////////
            ///// Edit Existing ///////////
            if (snapShot && indexing) {
              await setDoc(
                doc(DB, id, oneAirport, "comments", indexing.toString()),
                {
                  index: indexing,
                  displayName: displayName,
                  createdAt: new Date(),
                  comment: values.comment,
                }
              );
            }
            ///////////////////////////////////
            ///////// Add new Reivew ///////////
            if (snapShot && commentLength) {
              await setDoc(
                doc(DB, id, oneAirport, "comments", commentLength.toString()),
                {
                  index: commentLength,
                  displayName: displayName,
                  createdAt: new Date(),
                  comment: values.comment,
                }
              );
            }
          };
          /////////////////////////////////////////////////
          let path = `/states/${id}/${oneAirport}/comments`;
          history.replace({
            pathname: path,
            id: id,
            oneAirport: oneAirport,
            displayName: displayName,
          });
          onAddFirstReview();
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-hotelrv">
            <div className="inner-div-hotelrv">
              <div>
                <div>
                  <h1 className="hotel-h1-text">
                    You are adding a Comment for:
                  </h1>
                  <h2 className="hotel-h1-text" id="h1-review-airport">
                    {oneAirport} {indexing ? indexing : commentLength}
                  </h2>
                </div>
              </div>
              <div className="hotel-name">
                <div htmlFor="hotel-name" className="hotel-text">
                  Comment:
                </div>
                <Field
                  name="comment"
                  as="textarea"
                  className="input-box-hotelrv"
                />
                {errors.comment && touched.comment ? (
                  <div className="errors-txt">{errors.comment}</div>
                ) : null}
              </div>
              <div>
                <button type="submit" className="review-button">
                  SUBMIT
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CommentReviewComponent;
