import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDjBUv2b_PMlqdMdxwp_06VvGobCxQBsFE",
  authDomain: "hotels-and-rest.firebaseapp.com",
  projectId: "hotels-and-rest",
  storageBucket: "hotels-and-rest.appspot.com",
  messagingSenderId: "463924342759",
  appId: "1:463924342759:web:5df86d09b01bbe18a29742",
  measurementId: "G-0T9F1625ZZ",
};

// Initialize Firebase
const fBaseApp = initializeApp(firebaseConfig);

//Turn this on later
// const analytics = getAnalytics(fBaseApp);

//Database
const DB = getFirestore(fBaseApp);

//Storage
const storage = getStorage(fBaseApp);

export { DB, storage};
