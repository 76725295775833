import React from "react";
import { Link, useHistory } from "react-router-dom";

import "./hotel-restaurants.styles.css";

import hotel from "../../images/hotelemoji.png";
import restaurants from "../../images/restaurants.png";
import airports from "../../images/airport-6.jpg";
import checklist from "../../images/checklist.png";

import Footer from "../footer/footer.component";

const HotelRest = () => {
  const history = useHistory();
  return (
    <div className="total-div">
      <div className="div-main">
        <Link to="/hotels/states" className="link">
          <div className="hotel">
            <h1 id="h1-tag">HOTELS</h1>
            <img
              src={hotel}
              alt="hotel"
              width="400px"
              height="200px"
              className="img-pic"
            />
          </div>
        </Link>

        <Link to="/restaurants/states" className="link">
          <div className="restaurants">
            <h1 id="h1-tag">RESTAURANTS</h1>
            <img
              src={restaurants}
              alt="restaurants"
              width="400px"
              height="200px"
              className="img-pic"
            />
          </div>
        </Link>

        <Link
          to={{ pathname: "https://acceptancechecklist.web.app/" }}
          target="_blank"
          className="link"
        >
          <div className="accept">
            <h1 id="h1-tag">ACCEPT CHECKLIST</h1>
            <img
              src={checklist}
              alt="checklist"
              width="400px"
              height="200px"
              className="img-pic"
            />
          </div>
        </Link>

        <Link to="/states" className="link">
          <div className="airports">
            <h1 id="h1-tag">AIRPORTS</h1>
            <img
              src={airports}
              alt="airports"
              width="400px"
              height="200px"
              className="img-pic"
            />
          </div>
        </Link>
      </div>
      <Footer />
      <Link
        to={{ pathname: "https://discord.gg/NMWnUbtx" }}
        target="_blank"
        className="discord-link"
      >
        <div className="discord-channel">
          <h1 id="h1-tag">Come Join US at DISCORD</h1>
        </div>
      </Link>
    </div>
  );
};

export default HotelRest;
