import React from "react";
import { useHistory } from "react-router-dom";
import HotelReviewComponent from "../hotel-review-form/hotel-rv-form.component";

import "./ht-noreview.styles.css";

const HTnoReview = (props) => {
  const history = useHistory();
  const myId = history.location.state.myId;
  const oneAirport = history.location.state.oneAirport;
  const displayName = history.location.state.displayName;
  const snapShot = history.location.state.snapShot;

  return (
    <HotelReviewComponent
      myId={myId}
      oneAirport={oneAirport}
      displayName={displayName}
      snapShot={snapShot}
    />
  );
};

export default HTnoReview;
