import React from "react";
import { Field, Formik, Form } from "formik";
import { useHistory } from "react-router";

import { useState } from "react";
import { DB } from "../../utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";
import StarExistingRating from "../stars/star-existing.component";

import "../hotel-review-form/hotel-rv-form.styles.css";
import "./ht-viewinfo.style.css";

const HotelViewOnly = (props) => {
  const history = useHistory();
  const myId = history.location.state.myId;
  const oneAirport = history.location.state.oneAirport;
  const displayName = history.location.state.displayName;
  const snapShot = history.location.state.snapShot;
  const indexing = history.location.state.indexing;

  const [rating, setRating] = useState(0);
  let [isLoading, setLoading] = useState(true);
  const [hotelName, sethtName] = useState("");
  const [hotelAdd, sethtAdd] = useState("");
  const [hotelPhone, sethtPhone] = useState("");
  const [hotelBreakfast, sethtBreak] = useState("");
  const [hotelGym, sethtGym] = useState("");
  const [hotelLaundry, sethtLaundry] = useState("");
  const [hotelNoise, sethtNoise] = useState("");
  const [hotelOther, sethtOther] = useState("");
  const [hotelQuality, sethtQual] = useState("");
  const [hotelRest, sethtRest] = useState("");
  const [hotelSaftey, sethtSaftey] = useState("");
  const [hotelShuttle, sethtShuttle] = useState("");

  useEffect(async () => {
    if (snapShot !== null && indexing) {
      const docRef = doc(DB, myId, oneAirport, "hotels", indexing.toString());
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let hotelName = docSnap.data().hotelName;
        let hotelAdd = docSnap.data().hotelAdd;
        let hotelPhone = docSnap.data().hotelPhone;
        let hotelBreakfast = docSnap.data().hotelBreakfast;
        let hotelGym = docSnap.data().hotelGym;
        let hotelLaundry = docSnap.data().hotelLaundry;
        let hotelNoise = docSnap.data().hotelNoise;
        let hotelOther = docSnap.data().hotelOther;
        let hotelQuality = docSnap.data().hotelQuality;
        let hotelRest = docSnap.data().hotelRest;
        let hotelSaftey = docSnap.data().hotelSaftey;
        let hotelShuttle = docSnap.data().hotelShuttle;
        let rating = docSnap.data().rating;

        setRating(rating);
        sethtName(hotelName);
        sethtAdd(hotelAdd);
        sethtPhone(hotelPhone);
        sethtBreak(hotelBreakfast);
        sethtGym(hotelGym);
        sethtLaundry(hotelLaundry);
        sethtNoise(hotelNoise);
        sethtOther(hotelOther);
        sethtQual(hotelQuality);
        sethtRest(hotelRest);
        sethtSaftey(hotelSaftey);
        sethtShuttle(hotelShuttle);
      }
    }

    setLoading(false);
  }, [isLoading]);

  return (
    <div className="viewonly-total">
      <Formik
        enableReinitialize
        initialValues={{
          hotelName: hotelName ? hotelName : "",
          hotelAdd: hotelAdd ? hotelAdd : "",
          hotelPhone: hotelPhone ? hotelPhone : "",
          hotelQuality: hotelQuality ? hotelQuality : "",
          hotelBreakfast: hotelBreakfast ? hotelBreakfast : "",
          hotelGym: hotelGym ? hotelGym : "",
          hotelRest: hotelRest ? hotelRest : "",
          hotelShuttle: hotelShuttle ? hotelShuttle : "",
          hotelLaundry: hotelLaundry ? hotelLaundry : "",
          hotelNoise: hotelNoise ? hotelNoise : "",
          hotelSaftey: hotelSaftey ? hotelSaftey : "",
          hotelOther: hotelOther ? hotelOther : "",
          rating: rating ? rating : "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {() => (
          <Form className="form-hotelrv">
            <div className="inner-div-hotelrv">
              <div>
                <div>
                  <h1 className="hotel-h1-text">Viewing Review for:</h1>
                  <h2 className="hotel-h1-text" id="h1-review-airport">
                    {oneAirport} Review {indexing}
                  </h2>
                </div>
                <div>
                  <h2 className="hotel-h2-text">
                    Enter N/A where not applicable
                  </h2>
                </div>
              </div>

              <div className="hotel-name">
                <div htmlFor="hotel-name" className="hotel-text">
                  Hotel Name:
                </div>
                <Field
                  name="hotelName"
                  as="textarea"
                  className="input-box-nameaddphone"
                />
              </div>

              <div className="hotel-address">
                <div htmlFor="hotel-address" className="address-text">
                  Hotel Address:
                </div>
                <Field
                  name="hotelAdd"
                  as="textarea"
                  className="input-box-nameaddphone"
                />
              </div>

              <div className="hotel-phone">
                <div htmlFor="hotel-phone" className="phone-text">
                  Hotel Phone:
                </div>
                <Field
                  name="hotelPhone"
                  as="textarea"
                  className="input-box-nameaddphone"
                />
              </div>

              <div className="hotel-quality">
                <div htmlFor="hotel-quality" className="quality-text">
                  Hotel Quality:
                </div>
                <Field
                  name="hotelQuality"
                  as="textarea"
                  className="input-box-hotelrv"
                />
              </div>

              <div className="hotel-breakfast">
                <div htmlFor="hotel-breakfast" className="breakfast-text">
                  Hotel Breakfast:
                </div>
                <Field
                  name="hotelBreakfast"
                  as="textarea"
                  className="input-box-hotelrv"
                />
              </div>

              <div className="hotel-gym">
                <div htmlFor="hotel-gym" className="gym-text">
                  Hotel Gym:
                </div>
                <Field
                  name="hotelGym"
                  as="textarea"
                  className="input-box-hotelrv"
                />
              </div>

              <div className="hotel-rest">
                <div htmlFor="hotel-rest" className="rest-text">
                  Hotel Restaurant:
                </div>
                <Field
                  name="hotelRest"
                  as="textarea"
                  className="input-box-hotelrv"
                />
              </div>

              <div className="hotel-shuttle">
                <div htmlFor="hotel-shuttle" className="shuttle-text">
                  Hotel Shuttle Service:
                </div>
                <Field
                  name="hotelShuttle"
                  as="textarea"
                  className="input-box-hotelrv"
                />
              </div>

              <div className="hotel-laundry">
                <div htmlFor="hotel-laundry" className="laundry-text">
                  Hotel Laundry Service:
                </div>
                <Field
                  name="hotelLaundry"
                  as="textarea"
                  className="input-box-hotelrv"
                />
              </div>

              <div className="hotel-noise">
                <div htmlFor="hotel-noise" className="noise-text">
                  Hotel Noise Considerations:
                </div>
                <Field
                  name="hotelNoise"
                  as="textarea"
                  className="input-box-hotelrv"
                />
              </div>

              <div className="hotel-saftey">
                <div htmlFor="hotel-saftey" className="saftey-text">
                  Hotel Saftey Concerns:
                </div>
                <Field
                  name="hotelSaftey"
                  as="textarea"
                  className="input-box-hotelrv"
                />
              </div>

              <div className="hotel-other">
                <div htmlFor="hotel-other" className="other-text">
                  Hotel Other Notes:
                </div>
                <Field
                  name="hotelOther"
                  as="textarea"
                  className="input-box-hotelrv"
                />
              </div>
              <div className="other-text">Enter Star Rating:</div>
              <StarExistingRating rating={rating} size={50} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default HotelViewOnly;
