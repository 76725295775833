import React from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import StarRating from "../stars/starrating.component";

import "../hotel-review-form/hotel-rv-form.styles.css";
import { useState } from "react";
import { DB } from "../../utils/firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";

const ReviewSchema = Yup.object().shape({
  restName: Yup.string().min(6, "5 Characters?").required("Required"),
  restAdd: Yup.string().required("Required"),
  restPhone: Yup.string()
    .min(10, "With Area Code this Should be 10 digits?")
    .required("Required"),
  restOther: Yup.string().required("Required"),
});

const RestReviewComponent = (props) => {
  const history = useHistory();
  const myId = props.myId;
  const oneAirport = props.oneAirport;
  const displayName = props.displayName;
  const snapShot = props.snapShot;
  const indexing = history.location.state.indexing;
  const reviewLength = history.location.state.reviews;

  const [rating, setRating] = useState(0);
  let [isLoading, setLoading] = useState(true);
  const [restName, setrestName] = useState("");
  const [restAdd, setrestAdd] = useState("");
  const [restPhone, setrestPhone] = useState("");
  const [restOther, setrestOther] = useState("");

  function log(value) {
    setRating(value);
  }
  useEffect(async () => {
    if (snapShot !== null && indexing) {
      const docRef = doc(DB, myId, oneAirport, "rest", indexing.toString());
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let restName = docSnap.data().restName;
        let restAdd = docSnap.data().restAdd;
        let restPhone = docSnap.data().restPhone;
        let restOther = docSnap.data().restOther;
        let rating = docSnap.data().rating;

        setRating(rating);
        setrestName(restName);
        setrestAdd(restAdd);
        setrestPhone(restPhone);
        setrestOther(restOther);
      }
    }

    setLoading(false);
  }, [isLoading]);

  return (
    <div className="hotel-total-div">
      <Formik
        enableReinitialize
        initialValues={{
          restName: restName ? restName : "",
          restAdd: restAdd ? restAdd : "",
          restPhone: restPhone ? restPhone : "",
          restOther: restOther ? restOther : "",
        }}
        validationSchema={ReviewSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          ////////////FUNCTION add First /////////////
          const onAddFirstReview = async () => {
            if (snapShot === null) {
              /////////////////////////////////////
              ///Add First Review/////////////////
              await setDoc(doc(DB, myId, oneAirport, "rest", "1"), {
                index: 1,
                displayName: displayName,
                createdAt: new Date(),
                rating: rating,
                restName: values.restName,
                restAdd: values.restAdd,
                restPhone: values.restPhone,
                restOther: values.restOther,
              });
            }
            ////////////////////////////////
            ///// Edit Existing ///////////
            if (snapShot && indexing) {
              await setDoc(
                doc(DB, myId, oneAirport, "rest", indexing.toString()),
                {
                  index: indexing,
                  displayName: displayName,
                  createdAt: new Date(),
                  rating: rating,
                  restName: values.restName,
                  restAdd: values.restAdd,
                  restPhone: values.restPhone,
                  restOther: values.restOther,
                }
              );
            }
            ///////////////////////////////////
            ///////// Add new Reivew ///////////
            if (snapShot && reviewLength) {
              await setDoc(
                doc(DB, myId, oneAirport, "rest", reviewLength.toString()),
                {
                  index: reviewLength,
                  displayName: displayName,
                  createdAt: new Date(),
                  rating: rating,
                  restName: values.restName,
                  restAdd: values.restAdd,
                  restPhone: values.restPhone,
                  restOther: values.restOther,
                }
              );
            }
          };
          /////////////////////////////////////////////////
          let path = `/restaurants/states/${myId}/${oneAirport}`;
          history.replace({
            pathname: path,
            id: myId,
            oneAirport: oneAirport,
            displayName: displayName,
          });
          onAddFirstReview();
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-hotelrv">
            <div className="inner-div-hotelrv">
              <div>
                <div>
                  <h1 className="hotel-h1-text">
                    You are adding a Review for:
                  </h1>
                  <h2 className="hotel-h1-text" id="h1-review-airport">
                    {oneAirport} Review {indexing ? indexing : reviewLength}
                  </h2>
                </div>
                <div>
                  <h2 className="hotel-h2-text">
                    Enter N/A where not applicable
                  </h2>
                </div>
              </div>

              <div className="hotel-name">
                <div htmlFor="hotel-name" className="hotel-text">
                  Restaurant Name:
                </div>
                <Field
                  name="restName"
                  as="textarea"
                  className="input-box-nameaddphone"
                />
                {errors.restName && touched.restName ? (
                  <div className="errors-txt">{errors.restName}</div>
                ) : null}
              </div>

              <div className="hotel-address">
                <div htmlFor="hotel-address" className="address-text">
                  Restaurant Address:
                </div>
                <Field
                  name="restAdd"
                  as="textarea"
                  className="input-box-nameaddphone"
                />
                {errors.restAdd && touched.restAdd ? (
                  <div className="errors-txt">{errors.restAdd}</div>
                ) : null}
              </div>

              <div className="hotel-phone">
                <div htmlFor="hotel-phone" className="phone-text">
                  Restaurant Phone:
                </div>
                <Field
                  name="restPhone"
                  as="textarea"
                  className="input-box-nameaddphone"
                />
                {errors.restPhone && touched.restPhone ? (
                  <div className="errors-txt">{errors.restPhone}</div>
                ) : null}
              </div>

              <div className="hotel-other">
                <div htmlFor="hotel-other" className="other-text">
                  Restaurant Other Notes:
                </div>
                <Field
                  name="restOther"
                  as="textarea"
                  className="input-box-hotelrv"
                />
                {errors.restOther && touched.restOther ? (
                  <div className="errors-txt">{errors.restOther}</div>
                ) : null}
              </div>
              <div className="other-text">Enter Star Rating:</div>
              <StarRating onChange={log} size={50} />
              <div>
                <button type="submit" className="review-button">
                  SUBMIT
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RestReviewComponent;
