import React from "react";
import { useHistory } from "react-router-dom";
import CommentReviewComponent from "../airport-comment-rv-form/comment-rv-form.component";

import "../hotel-noreview/ht-noreview.styles.css";

const NoComment = (props) => {
  const history = useHistory();
  const id = history.location.state.id;
  const oneAirport = history.location.state.oneAirport;
  const displayName = history.location.state.displayName;
  const snapShot = history.location.state.snapShot;

  return (
    <CommentReviewComponent
      id={id}
      oneAirport={oneAirport}
      displayName={displayName}
      snapShot={snapShot}
    />
  );
};

export default NoComment;
