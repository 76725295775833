import React from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import { registerUser } from "../../store/actions";

import "../sign-up/sign-up.styles.css";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Must be greater then TWO Characters")
    .max(15, "Must Be Less than 15 characters")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Must be greater then TWO Characters")
    .max(15, "Must Be Less than 15 characters")
    .required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  displayName: Yup.string().required("Required"),
});

function validateDisplayName(value) {
  let error;
  if (
    value == "admin" ||
    value == "Admin" ||
    value == "administrator" ||
    value == "Administrator"
  ) {
    error = "Nice Try";
  }
  return error;
}

const SignUp = () => {
  let history = useHistory();
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        displayName: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        registerUser(values);
        setSubmitting(false);
        history.push("/");
      }}
    >
      {({ errors, touched, validateField }) => (
        <Form className="sign-up-form-main">
          <div className="sign-up-inner-div">
            <div >
              <h1 className="h1-signup">SIGN UP</h1>
            </div>

            <div className="div-firstname">
              <div htmlFor="firstName" className="firstname-txt">
                First Name:
              </div>
              <Field name="firstName" type="text" className="input-box-sign-up" />
              {errors.firstName && touched.firstName ? (
                <div className="errors">{errors.firstName}</div>
              ) : null}
            </div>

            <div className="div-lastname">
              <div htmlFor="lastName" className="lastname-txt">
                Last Name:
              </div>
              <Field name="lastName" type="text" className="input-box-sign-up" />
              {errors.lastName && touched.lastName ? (
                <div className="errors">{errors.lastName}</div>
              ) : null}
            </div>

            <div className="div-email">
              <div htmlFor="email" className="email-txt">
                Email:
              </div>
              <Field name="email" type="email" className="input-box-sign-up" />
              {errors.email && touched.email ? (
                <div className="errors">{errors.email}</div>
              ) : null}
            </div>

            <div className="div-password">
              <div htmlFor="password" className="password-txt">
                Password:
              </div>
              <Field name="password" type="password" className="input-box-sign-up" />
              {errors.password && touched.password ? (
                <div className="errors">{errors.password}</div>
              ) : null}
            </div>

            <div className="div-password">
              <div htmlFor="confirmPassword" className="password-txt">
                Confirm Password:
              </div>
              <Field
                name="confirmPassword"
                type="password"
                className="input-box-sign-up"
              />
              {errors.confirmPassword && touched.confirmPassword ? (
                <div className="errors">{errors.confirmPassword}</div>
              ) : null}
            </div>

            <div className="div-password">
              <div htmlFor="displayName" className="password-txt">
                Display Name:
              </div>
              <Field
                name="displayName"
                type="text"
                className="input-box-sign-up"
                validate={validateDisplayName}
              />
              {errors.displayName && touched.displayName ? (
                <div className="errors">{errors.displayName}</div>
              ) : null}
            </div>

            <div className="div-button">
              <button type="submit" className="button-txt">
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(SignUp);
