import React from "react";
import { useHistory } from "react-router-dom";
import RestReviewComponent from "../rest-review-form/rest-review-form.component";

import "../hotel-noreview/ht-noreview.styles.css";

const RestnoReview = (props) => {
  const history = useHistory();
  const myId = history.location.state.myId;
  const oneAirport = history.location.state.oneAirport;
  const displayName = history.location.state.displayName;
  const snapShot = history.location.state.snapShot;

  return (
    <RestReviewComponent
      myId={myId}
      oneAirport={oneAirport}
      displayName={displayName}
      snapShot={snapShot}
    />
  );
};

export default RestnoReview;
