import React from "react";
import { DB } from "../../utils/firebase";
import { useHistory } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import "./hotelreview-landingpage.styles.css";
import { useState } from "react";
import { useEffect } from "react";
import ExistingReviewCard from "../hotel-existing-review-card/existing-review.component";
import { ClipLoader } from "react-spinners";

const HotelReviewLandingPage = (props) => {
  const history = useHistory();
  const myLocation = history.location.pathname;
  const splitLocation = myLocation.split("/");
  const myId = splitLocation[3];
  const oneAirport = splitLocation[4];

  const override = {
    display: "block",
    margin: "0 auto",
    marginTop: "10%",
    borderColor: "black",
  };

  ///State Set up
  const [displayName, setDisplayName] = useState("");
  const [snapShot, setsnapShot] = useState(1);
  let [isLoading, setisLoading] = useState(true);

  useEffect(async () => {
    window.scrollTo(0, 0);
    isLoading = true;
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(DB, "users", user.uid);
      const userSnap = await getDoc(userRef);
      const displayName = userSnap.data().displayName;
      setDisplayName(displayName);
    }

    // Getting Reviews
    try {
      const docRef = doc(DB, myId, oneAirport, "hotels", "1");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setsnapShot(true);
      } else {
        setsnapShot(null);
      }
    } catch (err) {
      console.log(err);
    }
    setisLoading(false);
  }, [isLoading]);

  const handleNoReview = () => {
    let path = `/hotels/states/${myId}/${oneAirport}/hotelreviews/addone`;
    history.push({
      pathname: path,
      state: {
        myId: myId,
        oneAirport: oneAirport,
        displayName: displayName,
        snapShot: snapShot,
      },
    });
  };

  return (
    <div className="hotel-review-div">
      {snapShot !== null ? (
        isLoading ? (
          <ClipLoader
            cssOverride={override}
            size={300}
            color={"#00000"}
            speedMultiplier={1}
          />
        ) : (
          <ExistingReviewCard
            id={myId}
            oneAirport={oneAirport}
            displayName={displayName}
            snapShot={snapShot}
          />
        )
      ) : (
        <div className="no-reviews-div">
          <h1 className="no-reviews-h1">No Hotel Reviews Here yet</h1>
          <div className="add-reviews-no-reviews-div">
            <button
              className="button-comment-noreview"
              onClick={handleNoReview}
            >
              Click Me to Add Review
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelReviewLandingPage;
